.detection-score-container {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  position: relative;
  text-shadow: none;
  line-height: 1em;
  vertical-align: middle;
}
.detection-score-container .detection-score > span {
  color: var(--color-white);
  background-color: var(--color-light-grey);
  border-radius: var(--border-radius);
  padding: 0.05em 0.3em;
  cursor: pointer;
  margin-right: 0.2em;
}
.detection-score-container .detection-score > span > span.icon {
  font-size: 0.9em;
}
.detection-score-container .detection-score > span.ko {
  background-color: var(--color-ocra);
}

.detection-score_baloon {
  color: var(--color-blue);
  padding: 1.5rem 2rem;
  border-radius: var(--border-radius);
  min-width: 20rem;
  max-width: 25rem;
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
}
.detection-score_baloon > p {
  margin: 0 0 1em 0;
  text-align: center;
  white-space: normal;
}
.detection-score_baloon > div {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
.detection-score_baloon .alternatives {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  font-size: var(--font-size-small);
}

.detection-score-container.variant-transparent .detection-score {
  background-color: transparent;
  color: var(--color-light-grey);
  padding: 0;
}
.detection-score-container.variant-transparent.ko .detection-score {
  color: var(--color-ocra);
}

ul.detection-score-dictionary {
  white-space: normal;
  margin-bottom: 2em;
}
.detection-score_baloon > p .label-detected,
ul.detection-score-dictionary li {
  display: inline-block;
  white-space: nowrap;
  padding: 0.1em 0.3em;
  border: var(--border) var(--color-xlight-grey);
  border-radius: var(--border-radius-small);
  margin: 0.2em;
}
ul.detection-score-dictionary li.selected {
  background: var(--color-xlight-grey);
}
ul.detection-score-dictionary li .close-matching-label {
  margin-left: 0.5em;
  font-size: var(--font-size-xsmall);
}
