.slide-panel-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  gap: 1rem;
  position: relative;
}
.slide-panel-container .slide-panel-body {
  display: flex;
  gap: 1rem;
  flex-grow: 2;
  flex-direction: row;
  min-height: calc(100% - 4rem);
}

@container page-wrapper (max-aspect-ratio: 4/3) {
  .slide-panel-alternatives,
  .slide-panel-sidebar-container {
    max-height: 100%;
    overflow: auto;
    flex-grow: 2;
  }
  .slide-panel-container .slide-panel-body {
    flex-direction: column;
  }
  .slide-panel-container .slide-panel-main {
    flex-grow: 1;
    min-height: 50%;
    max-height: 50%;
  }
  .slide-panel-container.is-fullscreen .slide-panel-main {
    min-height: 100%;
    max-height: 100%;
  }
  .slide-panel-container .slide-panel-alternatives-inner,
  .slide-panel-container .slide-panel-sidebar-inner,
  .slide-panel-container .slide-panel-alternatives,
  .slide-panel-container .slide-panel-sidebar {
    min-width: 100% !important;
    width: 100% !important;
    flex-grow: 1;
    overflow: auto;
  }
  .slide-panel-container .slide-panel-alternatives-inner,
  .slide-panel-container .slide-panel-sidebar-inner {
    position: relative !important;
  }
}

.slide-panel-container.is-video .slide-panel-body {
  position: relative;
  left: -1rem;
  top: -1rem;
  height: calc(100% + 2rem);
  width: calc(100% + 2rem);
}
.slide-panel-container .slide-panel-main {
  flex-grow: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: gap 0.3s ease-in-out;
  overflow: auto;
}
.slide-panel-container.is-fullscreen .slide-panel-main {
  overflow: visible;
}
.slide-panel-container .slide-panel-alternatives,
.slide-panel-container .slide-panel-sidebar {
  min-width: 32rem;
  width: 32rem;
  position: relative;
  transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out;
}
.slide-panel-container .slide-panel-alternatives-inner,
.slide-panel-container .slide-panel-sidebar-inner {
  min-width: 32rem;
  width: 32rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: right 0.3s ease-in-out;
}

.slide-panel-container .slide-panel-sidebar-inner .slide-panel-sidebar-title {
  position: sticky;
  top: 0;
  display: flex;
  font-size: var(--font-size-xsmall);
  margin-bottom: 0.5rem;
  border-bottom: var(--border) var(--color-xlight-grey);
  z-index: 10;
  background: var(--color-xxxlight-grey);
}
.slide-panel-container .slide-panel-sidebar-inner h2 {
  padding: 0.5rem 0;
  margin: 0;
  flex-grow: 2;
  border-bottom: 0;
}
.slide-panel-container .slide-panel-sidebar-inner .slide-panel-sidebar-title .unusual-quality-criteria-count {
  background: var(--color-ocra);
  color: var(--color-white);
  padding: 0.1em 0.4em;
  border-radius: var(--border-radius-small);
  margin-left: 0.8em;
  letter-spacing: 0;
}

.slide-panel-container.is-fullscreen .examination-live-alternatives-sidebar,
.slide-panel-container.is-fullscreen .slide-panel-sidebar {
  min-width: 0;
  width: 0;
  overflow: hidden;
}

.slide-panel-container .slide-panel-image {
  flex-grow: 2;
  position: relative;
  width: 100%;
  height: 100%;
}
.slide-panel-container .slide-panel-image .slide-panel-image-inner {
  background-color: var(--color-common-black);
  position: relative;
  width: 100%;
  height: 100%;
}
.slide-panel-container.is-fullscreen .slide-panel-image .slide-panel-image-inner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.processing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  border: 0.5rem solid var(--color-blue);
  z-index: 10;
  border-radius: 5rem;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: slide-panel-processing-spin 1s infinite ease-in-out;
}
@keyframes slide-panel-processing-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.slide-panel-container .slide-panel-switchfullscreen {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.slide-panel-container .slide-panel-image .slide-panel-image-inner > img,
.slide-panel-container .slide-panel-image .slide-panel-image-inner > video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: var(--border-thick-size);
  transition: opacity 0.3s ease-in-out;
}
.slide-panel-container.is-fullscreen .slide-panel-image-inner {
  top: -0.5rem;
  left: -0.5rem;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
}

.slide-panel-container .slide-panel-waiting {
  font-size: var(--font-size-small);
  color: var(--color-xxlight-grey);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide-panel-waiting .slide-container {
  max-width: 10rem;
  opacity: 0.5;
}

.slide-panel-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  height: 3rem;
  position: relative;
  z-index: 20;
  padding-right: 3rem;
}

.slide-panel-slide-details {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.slide-panel-gotodx {
  margin-top: 0.5rem;
}

.slide-panel-container .slide-panel-slide-cta {
  font-size: var(--font-size-large);
  transition: opacity 0.3s ease-in-out;
}
.slide-panel-container.is-fullscreen .slide-panel-header,
.slide-panel-container.is-video .slide-panel-header {
  border-color: transparent;
  z-index: 100;
  position: absolute;
  width: calc(100% - 2rem);
}
.slide-panel-container.is-fullscreen .slide-panel-slide-name {
  opacity: 0;
  pointer-events: none;
}

.slide-panel-container .slide-panel-header .slide-panel-slide {
  transition: opacity 0.3s ease-in-out;
}
.slide-panel-container.is-fullscreen .slide-panel-header .slide-panel-slide {
  opacity: 0;
}

.slide-panel-pagination {
  text-align: center;
  font-size: var(--font-size-xxlarge);
  position: absolute;
  top: 0;
  left: 50%;
  translate: -50% 0;
  background: var(--color-xlight-blue-40);
  display: flex;
  z-index: 100;
}

.slide-panel-pagination > div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
}
.slide-panel-pagination > div:empty {
  cursor: default;
}

.slide-panel-pagination > div:hover {
  color: var(--color-black);
}

.slide-panel-score {
  min-width: 100%;
  text-align: center;
}

.slide-panel-options {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.slide-panel-print {
  position: relative;
}

.slide-panel-slide-container {
  position: relative;
  width: 100%;
  display: flex;
  gap: 1rem;
  height: 5rem;
}
.slide-panel-slide-container .slide-panel-slide-slide {
  min-width: 9rem;
  min-height: 9rem;
  aspect-ratio: 1 / 1;
  position: relative;
  top: -1rem;
  left: -1rem;
  border-right: var(--border) var(--color-xxlight-grey);
  border-bottom: var(--border) var(--color-xxlight-grey);
  display: flex;
  align-items: center;
  transition: width 0.3s ease-in-out;
  background: var(--color-xxxlight-grey);
  border-bottom-right-radius: var(--border-radius);
}
.slide-panel-slide-meta {
  display: flex;
  transition: padding 0.3s ease-in-out;
}
.slide-panel-slide-name {
  position: relative;
  font-size: var(--font-size-xlarge);
  line-height: var(--font-size-large);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out;
}
.slide-panel-container .slide-panel-slide-name .template-annotation {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  margin-left: 0.5em;
  white-space: nowrap;
  color: var(--color-light-grey);
  vertical-align: middle;
  display: inline-block;
}
.slide-panel-slide-name .slide-counter {
  display: inline-block;
  white-space: nowrap;
  margin-left: 0.5em;
  text-align: center;
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  color: var(--color-light-grey);
}
.slide-panel-container .pagination-counter {
  display: inline-flex;
  gap: 0.4em;
  border: var(--border);
  border-radius: var(--border-radius-small);
  white-space: nowrap;
  margin-right: 0.5em;
  text-align: center;
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  color: var(--color-light-grey);
  padding: 0.3em 0.5em;
}
.slide-panel-detection-score {
  font-size: var(--font-size-large);
  margin-top: 0.5rem;
  display: inline-block;
}
.slide-panel-slide-to-retake {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  white-space: nowrap;
  background: var(--color-ocra);
  color: var(--color-white);
  border-radius: var(--border-radius);
  padding: 0.3rem 0.5rem;
  margin-left: 0.5rem;
  display: inline-block;
  vertical-align: center;
  animation: slide-panel-reassociating-animation 3s infinite;
}
@keyframes slide-panel-reassociating-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.slide-panel-slide-category {
  display: inline-block;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-light-grey);
  margin-left: 0.3rem;
}
.slide-panel-slide-container .slide-panel-slide-change {
  font-size: var(--font-size-small);
  margin-bottom: 1rem;
}
.slide-panel-slide-container.active:hover {
  border-color: var(--color-blue);
}
.slide-panel-slide-container.active:hover .slide-panel-slide-change {
  color: var(--color-blue);
}
.slide-panel-slide-container .slide-panel-slide-feedback {
  text-align: center;
  width: 100%;
  display: block;
}

.slide-panel-slide-container .slide-panel-pagination {
  width: 100%;
  display: flex;
}
.slide-panel-slide-container .slide-panel-pagination > div:first-child,
.slide-panel-slide-container .slide-panel-pagination > div:last-child {
  cursor: pointer;
}
.slide-panel-slide-container .slide-panel-pagination > div:first-child:hover,
.slide-panel-slide-container .slide-panel-pagination > div:last-child:hover {
  background: var(--color-xxxlight-grey);
}
.slide-panel-slide-container .slide-panel-pagination > div {
  border: var(--border) var(--color-xxlight-grey);
  padding: 0.6rem;
}
.slide-panel-slide-container .slide-panel-pagination > div:nth-child(2) {
  text-align: center;
  flex-grow: 2;
}
.slide-panel-slide-container .slide-panel-pagination > div:nth-child(2) b {
  color: var(--color-black);
}

/* smart match */
.slide-panel-slide-container.smart-match .slide-panel-slide-slide {
  width: 12rem;
}
.slide-panel-slide-confirm-matching > h2 {
  font-size: var(--font-size-large);
  color: var(--color-blue);
}

/* video */

.slide-panel-image-evaluation,
.slide-panel-extracted-from-video {
  position: absolute;
  bottom: 0;
  z-index: 100;
  margin: 0.3rem;
  color: var(--color-xlight-grey);
  font-size: var(--font-size-small);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  width: 100%;
}
.slide-panel-container.is-fullscreen .slide-panel-image-evaluation,
.slide-panel-container.is-fullscreen .slide-panel-extracted-from-video {
  opacity: 0;
}
.slide-panel-container.is-video {
  display: block;
}
.slide-panel-container.is-video .slide-panel-body {
  display: block;
}
.slide-panel-container .slide-panel-video.inline {
  position: absolute;
  background: var(--color-common-black);
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
}
.slide-panel-container .slide-panel-video:not(.inline) {
  position: relative;
  width: calc(100% + 1rem);
  max-width: calc(100% + 1rem);
  background: var(--color-common-black);
  left: -0.5rem;
  height: calc(100vh - 1rem);
  object-fit: contain;
}
.slide-panel-container .slide-panel-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* checklist and quality criteria */
.slide-panel-searchbar {
  margin-bottom: 1rem;
  position: relative;
  z-index: 100;
}
.slide-panel-searchbar:last-child {
  margin-bottom: 0;
}

.slide-panel-checklist-items {
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
  position: relative;
  scroll-snap-type: x mandatory;
  margin-top: 0;
}
.slide-panel-checklist {
  margin-bottom: 2rem;
  position: relative;
}
.slide-panel-qc {
  flex-grow: 2;
  overflow: auto;
  position: relative;
}
.slide-panel-checklist-items .slide-panel-checklist-page {
  overflow: hidden;
  scroll-snap-align: center;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}
.slide-panel-checklist-items > ul,
.slide-panel-checklist-items > .slide-panel-checklist-page > ul {
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}
.slide-panel-checklist-items > ul > li,
.slide-panel-checklist-items > .slide-panel-checklist-page > ul > li {
  background: var(--color-white);
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.7rem 0.8rem;
  color: var(--color-black);
  font-size: var(--font-size-medium);
  line-height: var(--font-size-large);
  cursor: pointer;
}
.slide-panel-checklist-items > ul > li .exam-item-name,
.slide-panel-checklist-items > .slide-panel-checklist-page > ul > li .exam-item-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.slide-panel-checklist-items > ul > li .exam-item-context {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
}
.slide-panel-checklist-items > ul > li:first-child,
.slide-panel-checklist-items > .slide-panel-checklist-page > ul > li:first-child {
  margin-top: 0;
}
.slide-panel-checklist-items ul > li.anomaly {
  margin-bottom: 1rem;
}
.slide-panel-checklist-items ul > li.checked {
  color: var(--color-black);
}
.slide-panel-checklist-items ul > li.warning-mandatory.is-not-verified {
  color: var(--color-ocra);
}
.slide-panel-checklist-items ul > li.is-not-detected {
  color: var(--color-light-grey) !important;
}
.slide-panel-checklist-items ul > li.other {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.slide-panel-checklist-items ul > li.other label {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
}
.slide-panel-checklist-items ul > li.other label > span:last-child {
  flex-grow: 2;
}
.slide-panel-checklist-items ul > li.escape {
  margin-top: 1rem;
  border: 0;
  display: flex;
  gap: 1rem;
  padding: 0;
}
.slide-panel-checklist-items ul > li.escape > * {
  border: var(--border) var(--color-xxlight-grey);
  padding: 0.7rem 0.8rem;
}
.slide-panel-checklist-items ul > li.escape > .escape-end {
  flex-grow: 2;
}
.slide-panel-checklist-items ul > li .more {
  color: var(--color-light-grey);
}
.slide-panel-checklist-items ul > li:hover .more {
  color: var(--color-black);
}

.slide-panel-checklist-items .item-category {
  font-size: var(--font-size-xsmall);
  margin-left: 1em;
  color: var(--color-light-grey);
}
.slide-panel-checklist-items .item-progress {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin-left: 0.5rem;
  padding: 0.2rem 0.3rem;
  border-radius: var(--border-radius);
  white-space: nowrap;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  text-transform: uppercase;
}
.slide-panel-checklist-items .item-progress .icon {
  font-size: var(--font-size-normal);
}
.slide-panel-checklist-items ul > li.checked .item-progress.highlight {
  color: var(--color-white);
  background-color: var(--color-black);
}
.slide-panel-checklist-items ul > li.checked.warning-mandatory .item-progress.highlight {
  color: var(--color-white);
  background-color: var(--color-ocra);
}
.slide-panel-checklist-items .quality-criteria,
.slide-panel-checklist-items .risk-factors {
  margin-top: 0.3rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  color: var(--color-light-grey);
  line-height: 1.2;
}
.slide-panel-checklist-items .quality-criteria {
  color: var(--color-ocra);
}

.slide-panel-checklist-items .slide-panel-checklist-items-scroll-up,
.slide-panel-checklist-items .slide-panel-checklist-items-scroll-down {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: linear-gradient(var(--color-xxxlight-grey), transparent);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.slide-panel-checklist-items .slide-panel-checklist-items-scroll-down {
  transform: rotate(180deg);
  top: auto;
  bottom: 0;
}
.slide-panel-checklist-items .slide-panel-checklist-items-scroll-up.visible,
.slide-panel-checklist-items .slide-panel-checklist-items-scroll-down.visible {
  opacity: 1;
}
