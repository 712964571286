.dx-checklist-summary-container {
  position: absolute;
  top: 0;
  left: calc(var(--dx-panel-width) * -1);
  min-width: var(--dx-panel-width);
  max-width: var(--dx-panel-width);
  z-index: 20;
  height: 100%;
  transition: left 0.3s ease-in-out;
  background-color: var(--color-xxxlight-grey);
  border-left: var(--border) var(--color-xxlight-grey);
}
.dx-checklist-summary-container.show {
  left: 0;
}
.dx-checklist-summary-container.show .close-panel {
  position: absolute;
  left: 100%;
  top: 0;
  width: 20rem;
  height: 100%;
  background: linear-gradient(to right, var(--color-white), transparent);
  opacity: 0.7;
}
.dx-checklist-summary {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: relative;
}

.dx-checklist-summary-reliability {
  background: var(--color-xxlight-grey-40);
  border-radius: var(--border-radius-small);
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: var(--font-size-small);
  text-align: center;
  font-weight: bold;
}
.dx-checklist-summary-reliability-meter {
  min-width: 3rem;
  max-width: 3rem;
}
.dx-checklist-summary-reliability-sentence {
  font-size: var(--font-size-small);
  margin-left: 1rem;
}
.dx-checklist-summary-reliability-sentence .icon {
  margin-right: 0.5em;
}

.dx-checklist-summary-container .dx-checklist-summary-syndromes {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  transition: margin-left 0.3s ease-in-out;
}
.dx-checklist-summary-container .dx-checklist-summary-main-panel {
  padding: 2rem;
  overflow: auto;
  min-width: 100%;
  max-width: 100%;
  flex-grow: 2;
}
.dx-checklist-summary-container .checklist-summary-end-examination {
  padding: 1rem 2rem;
  display: flex;
  gap: 1rem;
  border-top: var(--border) var(--color-xxlight-grey);
  align-items: center;
  box-shadow: var(--common-box-shadow);
}
.dx-checklist-summary-container .checklist-summary-end-examination .feedback {
  flex-grow: 2;
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
}
.dx-checklist-summary-container .checklist-summary-end-examination .cta {
  white-space: nowrap;
}

.dx-checklist-summary.show-syndrome .dx-checklist-summary-syndromes {
  margin-left: -100%;
}
.dx-checklist-summary .diagnostic-panel-is-healty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dx-checklist-summary-container .dx-checklist-summary-not-relevant-syndromes,
.dx-checklist-summary-container .dx-checklist-summary-hidden-syndromes {
  margin-top: 5rem;
}
.dx-checklist-summary-container .dx-checklist-summary-hidden-syndromes h6,
.dx-checklist-summary-container .dx-checklist-summary-not-relevant-syndromes h6 {
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: var(--font-size-small);
  text-align: center;
  font-weight: bold;
}

/* syndrome panel */
.dx-checklist-summary-container .dx-checklist-summary-syndrome {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}
