.exam-report-carry-forward-section_suggestions {
  margin: 1rem 0 0 2rem;
  line-height: 1.2;
}

.exam-report-carry-forward-section_suggestions-actions {
  font-size: var(--font-size-xsmall);
  text-transform: uppercase;
  font-weight: normal;
  color: var(--color-black);
  letter-spacing: 0.2em;
  padding-bottom: 0.8em;
  padding-top: 0.8em;
}

.exam-report-carry-forward-section_suggestions .section-title .fold {
  margin-right: 0.8em;
  margin-bottom: 0.3em;
}

.exam-report-carry-forward-section_suggestions .exam-report-carry-forward-section_suggestion {
  padding: 0.4rem 0.2rem;
  font-size: var(--font-size-small);
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}
.exam-report-carry-forward-section_suggestions .exam-report-carry-forward-section_suggestion:hover {
  color: var(--color-black);
}
.exam-report-carry-forward-section_suggestions .exam-report-carry-forward-section_suggestion > .code {
  min-width: 4rem;
}
.exam-report-carry-forward-section_suggestions .exam-report-carry-forward-section_suggestion > .meta {
  display: flex;
  gap: 0;
  flex-wrap: wrap;
}
.exam-report-carry-forward-section_suggestions .exam-report-carry-forward-section_suggestion > .meta > .description {
  margin-right: 1rem;
}
.exam-report-carry-forward-section_suggestions .exam-report-carry-forward-section_suggestion > .meta > .context {
  color: var(--color-xlight-grey);
  font-size: var(--font-size-xsmall);
  align-self: flex-end;
}
