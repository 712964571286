.custom-medical-history-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: auto;
  margin-left: var(--section-navbar-width);
  position: relative;

  .custom-medical-history-header {
    width: 100%;
  }
  .custom-medical-history-header h2 {
    margin: auto;
  }
  .exam-report-content {
    padding: 0;
  }
  .exam-report-frozen-warning {
    z-index: 100;
  }
  .custom-medical-history-section {
    padding-top: 3rem;
  }
}
