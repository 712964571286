.examination-review-header {
  position: sticky;
  top: 0;
  z-index: 100;
  background: var(--color-xxxlight-grey);
  display: flex;
  box-shadow: var(--common-box-shadow);
}

.examination-review {
  padding: 0 3rem 0 7rem;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  overflow: auto;
}

.examination-review-patient-container {
  position: fixed;
  top: 0.7rem;
  right: 0.7rem;
  z-index: 100;
  background-color: var(--color-xxxlight-grey);
  padding: 0.6rem 1rem;
  border-radius: var(--border-radius-large);
  font-size: var(--font-size-medium);
  box-shadow: var(--common-box-shadow);
  display: flex;
  display: none;
}

.examination-review-submenu {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 10;
  background: var(--color-xxxlight-grey);
  display: flex;
  justify-content: right;
  font-size: var(--font-size-small);
  box-shadow: var(--common-box-shadow);
  border-radius: var(--border-radius-large);
  padding: 0.2rem 0.5rem;
}

.examination-review-submenu > * {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.examination-review-submenu > *:hover {
  color: var(--color-xlight-blue);
}

.examination-review-submenu > *:last-child {
  border-right: 0;
}

.examination-review-submenu .icon {
  margin-right: 0.4em;
}

.examination-review-chapter {
  min-height: calc(100vh + 10rem);
  /*scroll-snap-align: start;*/
  margin-bottom: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: relative;
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
}

.examination-review-chapter .row > div.cards {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.examination-review-chapter .row > div .card {
  padding: 1rem;
  margin: 0 0.1rem;
  height: 100%;
  border-radius: var(--border-radius-small);
  border: var(--border) var(--color-xxlight-grey);
}

.theme-dark .examination-review-chapter .row > div .card {
  box-shadow: var(--common-box-shadow);
}

/* dashboard */
.examination-review-chapter.dashboard h1 {
  text-align: center;
  animation: examination-review-fadein 1s ease-in-out forwards;
}

.examination-review-chapter.dashboard .examination-review-gotodetails {
  position: sticky;
  bottom: 0;
  text-align: center;
  color: var(--color-xlight-blue);
  width: calc(100% + 6rem);
  padding: 0 0 0.5rem;
  margin: 3rem 0 0 -3rem;
  cursor: pointer;
  background: var(--color-white);
  border-top: var(--border) var(--color-xxlight-grey);
}

.examination-review-chapter.dashboard .examination-review-gotodetails .icon {
  font-size: var(--font-size-xxlarge);
  display: block;
  margin: 0 auto;
}

.examination-review-chapter.dashboard .examination-review-next-steps-container {
  margin: 2rem 0;
  display: flex;
}

.examination-review-chapter.dashboard .examination-review-next-steps-container h2 {
  padding: 0;
  border-radius: var(--border-radius);
  margin: 0 2rem 0 0;
  border: 0;
  font-size: var(--font-size-large);
  opacity: 0;
  animation: examination-review-fadein 1s ease-in-out 1s forwards;
  text-align: left;
  max-width: 15rem;
}

.examination-review-chapter.dashboard .examination-review-next-steps-container .examination-review-next-steps {
  flex-grow: 2;
}

.examination-review-chapter.dashboard .examination-review-next-step {
  background: var(--color-xxxlight-grey);
  padding: 2rem;
  margin: 0 0 0.5rem 0;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  opacity: 0;
  animation: examination-review-fadein 1s ease-in-out 2s forwards;
}

.examination-review-next-step .icon {
  font-size: var(--font-size-xxlarge);
  margin-right: 1rem;
}

.examination-review-next-step .message {
  flex-grow: 2;
  margin-right: 1rem;
}

.examination-review-next-step .cta {
  white-space: nowrap;
}

@keyframes examination-review-fadein {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.examination-review-dashboard-messages {
  min-height: calc(80vh - 5rem);
}

.examination-review-gotomedias {
  text-align: center;
  margin-top: 3rem;
}

.examination-review-gotomedias .cta button {
  margin: 1rem;
}

.examination-review-gotohome {
  cursor: pointer;
  display: inline-block;
}

.examination-review-gotohome .icon {
  margin-right: 0.6rem;
}

/* /dashboard */

.examination-review h2 {
  font-size: var(--font-size-small);
  border-bottom: 1px solid var(--color-xlight-grey);
  color: var(--color-dark-grey);
  padding-bottom: 0.5rem;
  margin: 2rem 0 0.5rem 0;
}

.examination-review h2:first-child {
  margin-top: 0;
}

.examination-review h4 {
  text-transform: uppercase;
  font-size: var(--font-size-xsmall);
  border-bottom: var(--border) var(--color-light-grey);
  padding: 0 0 0.5em;
  margin: 0 0 0.5em;
  font-weight: normal;
  letter-spacing: 0.2em;
}

.examination-review #syndrome-details-0 h4 {
  padding-top: 3em;
}

.examination-review div.w4 > h4,
.examination-review div:first-of-type > h4 {
  padding-top: 0;
}

.examination-review .section-header {
  display: flex;
  align-items: flex-end;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.examination-review .section-header h2 {
  flex-grow: 2;
  margin: 0;
  margin-right: 1rem;
}

.examination-review .section-header button {
  font-size: var(--font-size-xsmall);
}

/* overview */
.examination-review .examination-review-overview-fetus {
  max-width: 10rem;
  margin: 0 auto;
}

.examination-review .examination-review-overview-zones ul {
  list-style: none;
  padding: 0;
}

.examination-review .examination-review-overview-zones ul li {
  margin: 0.3rem 0;
  display: flex;
}

.examination-review .examination-review-overview-zones ul li span:first-child {
  flex-grow: 2;
  align-items: baseline;
}

.examination-review .examination-review-overview-zones ul li span.validated {
  margin-left: 0.3rem;
  font-size: var(--font-size-xsmall);
}

.examination-review .examination-review-overview-zones ul li span.not-validated {
  margin-left: 0.3rem;
  font-size: var(--font-size-xsmall);
  font-weight: bold;
}

.examination-review .examination-review-overview-zones ul li span.unusual {
  margin-left: 0.3rem;
  font-size: var(--font-size-xsmall);
  color: var(--color-ocra);
}

.examination-review .examination-review-risk-factors {
  padding: 0;
}

.examination-review .examination-review-risk-factors ul {
  list-style: none;
  padding: 0;
}

.examination-review .examination-review-risk-factors ul li {
  margin: 0;
  padding: 0;
  transition: color 0.3s ease-in-out;
}

.examination-review .entry {
  margin: 0.1rem 0;
}

.examination-review .entry label {
  min-width: 30%;
  display: inline-flex;
  font-weight: bold;
  max-width: 40%;
  align-items: baseline;
  font-size: 0.8em;
  vertical-align: top;
}

.examination-review .entry label::after {
  content: '';
  height: 0.1em;
  min-width: 1em;
  flex-grow: 2;
  border-bottom: 0.2em dotted var(--color-xlight-grey);
}

.examination-review .entry span {
  display: inline-block;
  margin-left: 0.4em;
}

.examination-review .entry .raw-value {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 0.2em;
}

.examination-review .examination-review-risk-factors ul li.teratogenic-risks {
  margin: 0.4em 0;
}

.examination-review .examination-review-risk-factors ul li.teratogenic-risks label {
  width: 100%;
  max-width: 100%;
}

.examination-review .examination-review-risk-factors ul li.teratogenic-risks label::after {
  display: none;
}

.examination-review .examination-review-risk-factors ul li.teratogenic-risks span {
  display: block;
  margin-left: 20%;
  border-left: 0.2em dotted var(--color-xlight-grey);
  margin-top: 0;
  padding: 0.4em 0.8em;
}

.examination-review .examination-review-signs-item {
  padding: 0.4rem 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
}

.examination-review .examination-review-signs-item:first-child {
  padding-top: 0;
}

.examination-review .examination-review-signs-item:last-child {
  border-bottom: 0;
}

.examination-review .examination-review-signs-item.unusual {
  color: var(--color-ocra);
}

.examination-review .examination-review-medias label {
  display: block;
  margin: 0.5rem;
}

.examination-review .examination-review-medias label input {
  margin-right: 0.5rem;
}

.examination-review-biometries {
  font-size: var(--font-size-small);
}

.examination-review-biometries ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.examination-review-biometries ul li {
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0;
  border: var(--border);
  border-radius: var(--border-radius-large);
  min-width: 5.5rem;
}

.examination-review-biometries ul li.unusual {
  color: var(--color-ocra);
  font-weight: bold;
}

.examination-review .biometry-label {
  padding: 0.3em 0.6em;
  min-width: 1rem;
  text-align: center;
  border-radius: var(--border-radius-large);
  border: var(--border);
  display: inline-block;
}

.examination-review .biometry-value {
  min-width: 3rem;
  display: inline-block;
  text-align: center;
  padding: 0.3em 0.6em 0.3em 0;
}

.examination-review .reliability-index {
  padding: 1rem;
  border-radius: var(--border-radius-small);
  margin-bottom: 1rem;
  background: var(--color-xxxlight-grey);
  display: flex;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-small);
}

.examination-review .reliability-index-should-stop {
  font-weight: bold;
}

.examination-review .reliability-index .progress-ring-container {
  min-width: 3rem;
  max-width: 3rem;
  min-height: 3rem;
  max-height: 3rem;
  margin-right: 1rem;
}

.examination-review .examination-review-malformation {
  color: var(--color-ocra);
  padding: 0.2em 0;
}

.examination-review ul.examination-review-syndrome-list,
.examination-review ul.examination-review-syndrome-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.examination-review ul.examination-review-syndrome-list > li {
  border-top: var(--border) var(--color-xxlight-grey);
  margin: 0;
  padding: 0.8rem 0;
  position: relative;
}

.examination-review ul.examination-review-syndrome-list.relevant > li {
  margin: 0 0 3rem;
  padding: 0.8rem;
  border: var(--border) var(--color-xxlight-grey);
}

.examination-review ul.examination-review-syndrome-list.relevant .row {
  margin-bottom: 2rem;
}

.examination-review ul.examination-review-syndrome-list > li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 22%;
  border-bottom: var(--border-thick) var(--color-black);
}

.examination-review ul.examination-review-syndrome-list.relevant > li::before {
  left: 0.8rem;
}

.examination-review ul.examination-review-syndrome-list h3 {
  margin: 0.3rem 0 1rem;
  color: var(--color-black);
}

.examination-review ul.examination-review-syndrome-list h5 {
  margin: -0.5rem 0 1rem;
  font-weight: normal;
}

.examination-review-syndrome-list .details {
  display: flex;
  font-size: var(--font-size-xxsmall);
  align-items: center;
}

.examination-review-syndrome-list .details > .probability {
  flex-grow: 2;
}

.examination-review ul.examination-review-syndrome-list .probability,
.examination-review ul.examination-review-syndrome-list .prevalence,
.examination-review ul.examination-review-syndrome-list .publication-meta {
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
  margin-bottom: 0.2rem;
}

.examination-review ul.examination-review-syndrome-list small {
  color: var(--color-grey);
}

.malformations li {
  margin: 0;
  padding: 0.4em 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
  text-indent: -1.7em;
  padding-left: 1.7em;
}

.malformations .icon {
  border: var(--border) var(--color-xlight-grey);
  border-radius: var(--border-radius);
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  background: transparent;
  color: var(--color-white);
  padding: 0.1em;
  width: 1.2em;
  height: 1.2em;
}

.malformations .yes {
  color: var(--color-ocra);
}

.malformations .yes .icon {
  background-color: var(--color-ocra);
}

.malformations .no .icon,
.malformations .maybe .icon {
  background-color: var(--color-light-grey);
}

.malformations .unknown {
  color: var(--color-grey);
}

.malformations .unknown .icon {
  offset: inset var(--border) var(--color-light-grey);
}

/* share images */
.examination-review .row.examination-review-medias {
  padding-bottom: 5rem;
}

.examination-review .examination-review-media {
  padding: 0.8rem;
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius);
  height: 100%;
  transition: background-color 0.3s ease-in-out;
}

.examination-review .examination-review-media.shared {
  background: var(--color-xxlight-grey);
}

.examination-review .examination-review-media > .thumbnail {
  position: relative;
  height: 0;
  padding-bottom: 60%;
  background-color: var(--color-common-black);
  border-radius: var(--border-radius-small);
  overflow: hidden;
}

.examination-review .examination-review-media > .thumbnail > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.examination-review-container .sharing-code-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.examination-review-container .sharing-code-container .sharing-code-modal {
  height: auto;
  position: relative;
  z-index: 1100;
  background: var(--color-xxxlight-grey);
  box-shadow: var(--common-box-shadow);
  padding: 3rem;
  border-radius: var(--border-radius);
}

.examination-review-container .sharing-code-container .sharing-code-modal .sharing-form {
  width: 30vw;
  text-align: center;
}

.examination-review-container .sharing-code-container .sharing-code-modal .sharing-email {
  display: block;
  margin-bottom: 1rem;
}

.examination-review-container .sharing-code-container .sharing-code-modal .sharing-email .recipient {
  display: block;
}

.examination-review-container .sharing-code-container .sharing-code-modal .warning {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-small);
  margin-top: 1rem;
}

.examination-review-container .sharing-code-container .sharing-code {
  font-size: var(--font-size-xxxlarge);
  line-height: var(--font-size-xxxlarge);
}

.examination-review-container .sharing-code-container .sharing-code span {
  border-bottom: var(--border-thick) var(--color-xlight-grey);
  margin: 0.5rem;
  display: inline-block;
  min-width: 1em;
  text-align: center;
}

.examination-review-container .examination-review-checklist-slides .examination-review-checklist-slide-header {
  display: flex;
  font-size: var(--font-size-xsmall);
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  border-bottom: var(--border) var(--color-xxlight-grey);
  align-items: center;
}

.examination-review-container .examination-review-checklist-slides h3 {
  flex-grow: 2;
  font-size: var(--font-size-small);
  margin: 0;
}

.examination-review-container .examination-review-checklist-slides ul {
  list-style: none;
  padding: 0;
}

.examination-review-container .examination-review-checklist-slides ul li {
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.examination-review-container .examination-review-checklist-slides ul li .label {
  flex-grow: 2;
}

.examination-review-container .examination-review-checklist-slides ul li .edit {
  white-space: nowrap;
  font-size: var(--font-size-xsmall);
}

.examination-review-container .examination-review-checklist-slides ul li.status-unusual .label {
  color: var(--color-ocra);
}

.examination-review-syndrome-risk-factors .risk-factor {
  display: flex;
}

.examination-review-syndrome-risk-factors .risk-factor.yes {
  color: var(--color-ocra);
}

.examination-review-syndrome-risk-factors .risk-factor .label {
  min-width: 50%;
  margin: 0.1rem 0;
}
