.slide-container-dt {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  border: var(--border-thick) transparent;
}
.slide-container-dt > .slide-container-dt-slide {
  transform: scaleX(1);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
}
.slide-container-dt:not(.prevent-animations) > .slide-container-dt-slide {
  transition: transform 0.3s ease-in-out 0.3s;
}
.slide-container-dt.is-selected {
  opacity: 1 !important;
  border: var(--border-thick) var(--color-ocra);
}
.slide-container-dt.is-automatched-next {
  border: var(--border-dashed-thick) var(--color-common-blue);
}

.slide-container-dt.rotate-flip > .slide-container-dt-slide {
  transform: scaleX(-1);
}
.slide-container-dt > .slide-container-dt-slide > img,
.slide-container-dt > .slide-container-dt-slide .slide-container-dt-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(0);
}
.slide-container-dt:not(.prevent-animations) > .slide-container-dt-slide > img,
.slide-container-dt:not(.prevent-animations) > .slide-container-dt-slide .slide-container-dt-map {
  transition: transform 0.3s ease-in-out;
}
.slide-container-dt .slide-container-dt-map {
  z-index: 10;
}
.slide-container-dt .slide-container-dt-map svg {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}
.slide-container-dt.rotate-0 > .slide-container-dt-slide > img,
.slide-container-dt.rotate-0 > .slide-container-dt-slide .slide-container-dt-map {
  transform: rotate(0deg);
}
.slide-container-dt.rotate-90 > .slide-container-dt-slide > img,
.slide-container-dt.rotate-90 > .slide-container-dt-slide .slide-container-dt-map {
  transform: rotate(90deg);
}
.slide-container-dt.rotate-180 > .slide-container-dt-slide > img,
.slide-container-dt.rotate-180 > .slide-container-dt-slide .slide-container-dt-map {
  transform: rotate(180deg);
}
.slide-container-dt.rotate-270 > .slide-container-dt-slide > img,
.slide-container-dt.rotate-270 > .slide-container-dt-slide .slide-container-dt-map {
  transform: rotate(270deg);
}

.slide-container-dt .slide-count {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  z-index: 1;
  border: var(--border) var(--color-blue);
  border-radius: var(--border-radius-large);
  min-width: 1.4em;
  height: 1.4em;
  font-size: var(--font-size-medium);
  text-align: center;
  padding: 0 0.2em;
  color: var(--color-blue);
  background: var(--color-xxxlight-grey);
}
.slide-container-dt > .category {
  position: absolute;
  bottom: 0.3rem;
  left: 0.3rem;
  font-size: var(--font-size-xxsmall);
  color: var(--color-blue-40);
  line-height: var(--font-size-xsmall);
}
.slide-container-dt.is-completed > .slide-count {
  background: var(--color-blue);
  color: var(--color-white);
}

.slide-container-dt.has-risks {
  background: var(--color-darkcommon-xxlight-grey);
}

.slide-container-dt .slide-container-dt-map svg .fetus-path {
  fill: var(--color-xxxlight-grey);
}
.slide-container-dt .slide-container-dt-map svg circle.fetus-path {
  fill: var(--color-xxlight-grey);
}

.slide-container-dt .slide-container-dt-map svg > .item {
  fill: transparent;
}
.slide-container-dt .slide-container-dt-map svg > .map {
  fill: transparent;
  cursor: pointer;
}
.slide-container-dt .slide-container-dt-map svg > .item.unusual {
  fill: var(--color-common-ocra) !important;
  opacity: 1 !important;
}
.slide-container-dt.is-interactive .slide-container-dt-map svg > .item {
  fill: var(--color-common-blue);
}

.slide-container-dt.lowlight {
  background: transparent;
}
.slide-container-dt.lowlight .slide-container-dt-slide > img {
  opacity: 0.3;
}

.slide-container-dt.slide-other {
  border-radius: 50rem;
  overflow: visible;
}
.slide-container-dt-slide-other-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
.slide-container-dt-slide-other {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color-xxxlight-grey);
  border-radius: 20rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
