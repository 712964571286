.examination-live-scrollbar-container {
  position: relative;
  margin: 0 0.2rem 0 0;
  padding: 0;
  animation: examination-live-scrollbar-fadein 0.6s ease-in-out forwards;
  --scrollbar-width: 1rem;
}
@keyframes examination-live-scrollbar-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.examination-live-scrollbar-container ol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  margin: 0;
  padding: 0.2rem 0;
  list-style: none;
}
.examination-live-scrollbar-container li {
  position: relative;
  flex-grow: 2;
  width: calc(var(--scrollbar-width) + 0.2rem);
  padding: 0.1rem;
  cursor: pointer;
}
.examination-live-scrollbar-container li > span {
  display: block;
  position: absolute;
  width: var(--scrollbar-width);
  height: 100%;
  background: var(--color-common-blue);
  border: var(--border) var(--color-white);
  border-radius: 0.2rem;
}
.examination-live-scrollbar-container li.completed > span {
  background: transparent;
  border-color: var(--color-xxlight-grey);
}
.examination-live-scrollbar-container li.unusual > span {
  background: var(--color-ocra);
}
.examination-live-scrollbar-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--scrollbar-width);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.examination-live-scrollbar-highlight li {
  background: transparent;
  border: transparent;
  width: var(--scrollbar-width);
  padding: 0;
  margin: 0 0.1rem;
}
.examination-live-scrollbar-highlight li.highlight {
  border-radius: var(--border-radius-small);
  z-index: 0;
  background: var(--color-xlight-grey);
  outline: 0.2rem solid var(--color-xlight-grey);
}
