.page-container {
  container-type: size;
  container-name: page-wrapper;
  grid-area: main-page;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  flex-grow: 2;
  transform: translateX(0); /* ref for fixed-positioned children */
}
