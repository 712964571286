.slide-live-dt-container {
  width: 100%;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  position: relative;
  text-align: left;
}

.slide-live-dt-container.has-media.not-verified .slide-header .slide-title .icon {
  color: var(--color-common-white);
}
.slide-live-dt-container.is-other.not-verified.has-media:not(.trimester-ND) {
  outline: 0.3rem dotted var(--color-light-blue-40);
}

.slide-live-dt-container .slide-image-evaluation {
  font-size: var(--font-size-small);
  margin: 0.2em 0;
  color: var(--color-light-grey);
}
.slide-live-dt-container.zoom-level-3 .slide-image-evaluation {
  font-size: var(--font-size-medium);
}
.slide-live-dt-container .slide-qc-evaluation {
  position: absolute;
  left: 0.2rem;
  bottom: 0.5rem;
  background: var(--color-ocra);
  color: var(--color-white);
  display: inline-block;
  margin-left: 0.2em;
  line-height: 1em;
  padding: 0.15em 0.5em;
  border-radius: var(--border-radius-small);
  z-index: 200;
  font-size: var(--font-size-small);
}
.slide-live-dt-container.zoom-level-3 .slide-qc-evaluation {
  font-size: var(--font-size-medium);
}
.slide-live-dt-container .slide-qc-evaluation.pending {
  background: var(--color-black);
  border-radius: var(--border-radius-large);
}

.slide-live-dt-container.to-retake {
  animation: slide-live-container-reassociating-animation 3s infinite;
}
@keyframes slide-live-container-reassociating-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.slide-live-dt-container .slide-live-dt-protect-click {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.slide-live-dt-container .slide-header {
  color: var(--color-common-white);
  line-height: 1;
  display: flex;
  width: 100%;
  min-height: 4rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 120;
}
.slide-live-dt-container.has-media .slide-header:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 6rem;
  border-radius: var(--border-radius-small);
  opacity: 1;
  z-index: -1;
}
.slide-live-dt-container.has-media:not(.is-other) .slide-header:after {
  background: linear-gradient(var(--color-common-black), transparent);
}
.slide-live-dt-container .slide-header .slide-title {
  padding: 0.5rem;
  flex-grow: 2;
  text-shadow: 0 0.1em 0 var(--color-common-black), 0.1em 0 0 var(--color-common-black),
    0 -0.1em 0 var(--color-common-black), -0.1em 0 0 var(--color-common-black);
  pointer-events: none;
}
.slide-live-dt-container .slide-header .slide-title .icon {
  color: var(--color-xlight-grey);
}
.unusual > .slide-live-dt-container .slide-header .slide-title .icon {
  color: var(--color-ocra);
}
.slide-live-dt-container .slide-header .slide-title .slide-techno {
  font-size: var(--font-size-xsmall);
  padding: 0.1rem 0.2rem;
  margin-right: 0.3em;
  border: var(--border);
  border-radius: var(--border-radius-small);
  display: inline-block;
  vertical-align: middle;
}
.slide-live-dt-container .slide-header .slide-title .slide-counter {
  display: inline-block;
  white-space: nowrap;
  margin-left: 0.5em;
  text-align: center;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  color: var(--color-light-grey);
}

.slide-live-dt-container .slide-header .slide-title .slide-label {
  position: relative;
}
.slide-live-dt-container .slide-header .slide-title .slide-label .template-annotation {
  display: block;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  white-space: nowrap;
  color: var(--color-light-grey);
}

.slide-live-dt-container .slide-category {
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
  line-height: var(--font-size-small);
}

.slide-live-dt-container .slide-live-dt-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  color: var(--color-black);
  opacity: 0.5;
  font-size: var(--font-size-xxlarge);
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-in-out;
}
.slide-live-dt-container.to-retake .slide-live-dt-icon,
.slide-live-dt-container:hover .slide-live-dt-icon {
  opacity: 1;
}

.slide-live-dt-container .slide-live-dt-thumbnail {
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  transform: scaleX(1) rotate(0);
  transition: transform 0.3s ease-in-out;
}
.slide-live-dt-container.has-media .slide-live-dt-thumbnail {
  background: var(--color-common-black);
}
.slide-live-dt-container .slide-live-dt-media {
  animation: slide-live-fadein 0.6s ease-in-out forwards;
  position: absolute;
  z-index: 1;
}
.slide-live-dt-container .slide-live-dt-media img {
  height: 100%;
  width: 100%;
  background: var(--color-common-black);
  object-fit: contain;
  animation: slide-live-fadein 0.6s ease-in-out forwards;
}
.slide-live-dt-container .slide-live-dt-slide {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 20%;
  animation: slide-live-fadein 0.6s ease-in-out forwards;
}

@keyframes slide-live-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-live-dt-container .slide-live-dt-container-change {
  color: var(--color-light-grey);
  margin-right: -0.8rem;
  margin-top: -0.5rem;
  padding: 0.6rem 0.8rem;
}
.slide-live-dt-container .slide-live-dt-container-change:hover {
  color: var(--color-light-blue);
}
.slide-live-dt-container .slide-live-dt-container-starred {
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-large);
  opacity: 0.3;
}
.slide-live-dt-container .slide-live-dt-container-starred.selected {
  opacity: 1;
}
.slide-live-dt-container .slide-thumbnail {
  padding: 2rem;
}

.slide-live-dt-container.zoom-level-5 .slide-header {
  font-size: var(--font-size-small);
}
.slide-live-dt-container.zoom-level-5 .slide-category {
  font-size: var(--font-size-xxsmall);
}
.slide-live-dt-container.zoom-level-8 .slide-techno-icon {
  font-size: var(--font-size-medium);
}
.slide-live-dt-container.zoom-level-8 .slide-header {
  font-size: var(--font-size-xsmall);
}

.slide-live-dt-container .slide-live-dt-video-status {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  pointer-events: none;
  padding: 0.5rem;
}
.slide-live-dt-container .video-status-item {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
  white-space: nowrap;
  text-overflow: ellipsis;
  background: var(--color-black);
  color: var(--color-white);
  border-radius: var(--border-radius-large);
  padding: 0.3rem 0.5rem;
  display: inline-block;
  vertical-align: center;
  animation: slide-live-dt-video-status-animation 3s infinite;
}
@keyframes slide-live-dt-video-status-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
