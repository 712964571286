.simple-table {
  width: 100%;
  border-spacing: 0 1rem;
  border-collapse: collapse;
}

.exam-report-content .simple-table {
  /* remove the margin that is added by the drag and drop icon */
  margin-left: -1rem;
}

.simple-table > thead > tr > th {
  color: var(--color-black);
  background-color: var(--color-xxlight-grey-40);
  text-align: start;
  border: var(--border) var(--color-xxxlight-grey);
  padding: 0.2rem 0.5rem;
  font-size: var(--font-size-small);
  font-weight: normal;
}

.simple-table > tbody > tr > td {
  text-align: start;
  padding: 0.2rem 0.5rem;
  transition: color 0.25s;
  max-width: 10rem;
}

.simple-table > tbody > tr > td.actionable {
  color: var(--color-xlight-grey);
}

.simple-table > tbody > tr {
  border-bottom: var(--border) var(--color-light-grey);
}

.simple-table-empty-line {
  background-color: var(--color-xxlight-grey) !important;
}

.simple-table > thead > tr > th.no-left-border {
  border-left: none;
}

.simple-table > thead > tr > th.no-border {
  border: none;
}

.simple-table > thead > tr > th.no-right-border {
  border-right: none;
}

.simple-table > thead > tr > th.no-background-color {
  background-color: transparent;
}
