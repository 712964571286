.dx-container {
  display: flex;
  align-items: stretch;
  height: 100vh;

  /* CSS vars for diagnostics */
  --dx-panel-width: 50rem;
  --dx-sidebar-width: 14rem;
}

.dx-sidebar {
  background: var(--color-xxxlight-grey);
  max-width: var(--dx-sidebar-width);
  min-width: var(--dx-sidebar-width);
  z-index: 30;
}

.dx-checklist-zones {
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  height: 100vh;
  position: relative;
  width: calc(100vw - var(--dx-sidebar-width));
  z-index: 10;
  font-size: var(--font-size-medium);
}

@container page-wrapper (max-device-width: 1400px) {
  .dx-checklist-zones {
    font-size: var(--font-size-large);
  }
}
