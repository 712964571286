.examination-review-print-preview-container {
  position: fixed;
  z-index: 1019;
  background: var(--color-white);
  top: 0rem;
  left: 4rem;
  width: calc(100% - 4rem);
  height: 100%;
  overflow: auto;
  font-size: var(--font-size-medium);
  display: flex;
}

.examination-review-print-preview-container h2 {
  font-size: var(--font-size-xsmall);
  text-align: center;
  color: var(--color-black);
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-bottom: var(--border) var(--color-xxlight-grey);
}

.examination-review-print-preview-leftbar {
  width: 15rem;
  padding: 0;
  background: var(--color-xxxlight-grey);
  display: flex;
  flex-direction: column;
}
.examination-review-print-preview-images {
  flex-grow: 2;
  overflow: auto;
}
.examination-review-print-preview-images .media {
  margin: 0.5rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-small);
}
.examination-review-print-preview-images .slide-header {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-common-white);
  line-height: 1;
  padding: 0.5rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  border-radius: var(--border-radius);
  min-height: 4rem;
  z-index: 2;
}
.examination-review-print-preview-images .slide-header:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 6rem;
  background: linear-gradient(var(--color-common-black), transparent);
  opacity: 1;
  border-radius: var(--border-radius-small);
  z-index: -1;
}
.examination-review-print-preview-images .slide-header .slide-check {
  min-width: 2rem;
  min-height: 2rem;
  margin-right: 0.5rem;
  border: var(--border-thick) var(--color-xlight-grey);
  border-radius: var(--border-radius-small);
  color: var(--color-xlight-grey);
  font-size: var(--font-size-large);
  display: flex;
  align-items: center;
  justify-content: center;
}
.examination-review-print-preview-images .media.selected .slide-header .slide-check {
  color: var(--color-black);
  border: var(--border-thick) var(--color-black);
}
.examination-review-print-preview-images .slide-header .slide-title {
  flex-grow: 2;
}
.examination-review-print-preview-images .slide-category {
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
  line-height: var(--font-size-small);
}

.examination-review-print-preview-preview {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}
.examination-review-print-preview-iframe {
  flex-grow: 2;
  height: 100%;
  aspect-ratio: 21 / 29.7;
  display: block;
  margin: 0.5rem auto;
}
.examination-review-print-preview-iframe iframe {
  width: 100%;
  height: 100%;
}

.examination-review-print-preview-rightbar {
  width: 15rem;
  background: var(--color-xxxlight-grey);
  display: flex;
  flex-direction: column;
}
.examination-review-print-preview-settings {
  flex-grow: 2;
  padding: 1rem;
  font-size: var(--font-size-small);
}
.examination-review-print-preview-settings > .templates {
  padding: 1rem 0;
}
.examination-review-print-preview-settings > .templates ul {
  display: flex;
  gap: 0.5rem;
  justify-content: space-around;
}
.examination-review-print-preview-settings > .templates ul li {
  text-align: center;
  border-radius: var(--border-radius-small);
  border: var(--border-dashed-thick) var(--color-xlight-grey);
  padding: 1rem;
  cursor: pointer;
}
.examination-review-print-preview-settings > .templates ul li.selected {
  border: var(--border-thick) var(--color-blue);
}

.examination-review-print-preview-cta {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
