.inline-dropdown .inline-dropdown-value {
  display: flex;
  align-items: center;
  gap: 0.2em;
  min-width: 2em;
}

.inline-dropdown.is-active .inline-dropdown-value {
  cursor: pointer;
}

.inline-dropdown.is-active .inline-dropdown-value > div {
  min-height: 1em;
  min-width: 100%;
  border-bottom: var(--border-thick) var(--color-xlight-grey);
}

.inline-dropdown:is(:hover, .is-editing) .inline-dropdown-value > div {
  border-bottom-color: transparent;
}

.inline-dropdown .inline-dropdown-value > .icon {
  display: none;
  position: absolute;
  top: 0.2em;
  left: calc(100% + 0.2em);
  z-index: 30;
  transition: transform 0.3s ease-in-out;
}

.inline-dropdown:hover .inline-dropdown-value > .icon,
.inline-dropdown.is-editing .inline-dropdown-value > .icon {
  display: block;
}

.inline-dropdown.is-editing .inline-dropdown-value > .icon {
  transform: rotate(180deg);
}

.inline-dropdown.is-active:hover::after,
.inline-dropdown.is-active.is-editing::after {
  width: calc(100% + calc(var(--hover-padding-horizontal) * 2) + 1em);
}

.inline-dropdown .inline-dropdown-options {
  position: absolute;
  top: calc(100% + var(--hover-padding-vertical));
  left: calc(var(--hover-padding-horizontal) * -1);
  min-width: calc(100% + (var(--hover-padding-horizontal) * 2) + 1em);
  background-color: var(--color-white);
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow);
  overflow: auto;
  max-height: 70svh;
  z-index: 100001;
}

.inline-dropdown .inline-dropdown-option {
  padding: 0.5em var(--hover-padding-horizontal);
  border-bottom: var(--border) var(--color-xxxlight-grey);
  font-size: var(--font-size-small);
  white-space: nowrap;
  cursor: pointer;
}

.inline-dropdown .inline-dropdown-option.selected {
  background: var(--color-blue);
  color: var(--color-white);
}

.inline-dropdown .inline-dropdown-option:last-child {
  border-bottom: none;
}
