.page-container.preferences-per-protocol-selection {
  position: fixed;
  top: 0;
  z-index: 5;
  background-color: var(--color-white);
  height: 100vh;
  left: 4rem;
  width: calc(100% - 4rem);
  padding: 1em;
}

.preferences-per-protocol-selection .manage-template-slide-grid-container {
  height: calc(100vh - 6rem);
}

.configuration-container .personalised-edit-button {
  display: inline-block;
  vertical-align: top;
  padding: 0.1rem 0.5rem;
}

.configuration-container .left-navbar {
  background-color: var(--color-white);
}
