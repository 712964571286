.edit-flash-report-container {
  display: flex;
  flex-direction: column;
}

.edit-flash-report-container > button {
  width: fit-content;
}

.edit-flash-report-title {
  font-size: var(--font-size-medium);
  font-weight: 700;
  line-height: var(--font-size-medium);
  color: white;
}

.edit-flash-report-add-button {
  align-self: flex-end;
  margin-bottom: 2rem;
}

.edit-flash-report-close-button {
  align-self: center;
}

.edit-flash-report-templates-container {
  max-height: 40svh;
  overflow: scroll;
  scrollbar-width: auto;
}

.edit-flash-report-templates {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-flash-report-templates:not(:first-child) {
  margin-top: 0.8rem;
}

.edit-flash-report-template-buttons {
  display: flex;
  gap: 1rem;
}
