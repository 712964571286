.checkbox-input-wrapper {
  margin: 0.25rem 0.25rem 0 0;
}

.checkbox-input-wrapper.checked {
  border: var(--border) var(--color-blue);
  display: inline-block;
  border-radius: var(--border-radius-small);
  background-color: var(--color-blue);
  max-height: 0.875rem;
  max-width: 0.875rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-input-wrapper.checked svg {
  fill: var(--color-white);
}

.checkbox-input-wrapper.not-checked {
  border: var(--border) var(--color-blue);
  border-radius: var(--border-radius-small);
  display: inline-block;
  max-height: 0.875rem;
  min-height: 0.875rem;
  min-width: 0.875rem;
  cursor: pointer;
}
