.slide-share-display-container {
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-xlarge);
  display: flex;
  flex-direction: column;
  color: var(--color-blue);
}

.slide-share-display-container > div {
  padding: 0.5rem;
}

.slide-share-display-container > .unselected {
  opacity: 0.7;
}
