.password-checker-criteria {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: var(--font-size-large);
  color: var(--color-grey);
  gap: var(--font-size-medium);
  align-items: center;
}

.password-checker-criteria.checked {
  color: var(--color-dark-grey);
}

.password-checker-criteria-state {
  width: var(--font-size-medium);
  height: var(--font-size-medium);
}
