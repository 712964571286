.examination-live-phenotype-panel-container {
  display: flex;
  gap: 3rem;
  position: relative;
  height: 100%;
  padding: 3rem;
}

.examination-live-phenotype-panel-container > div {
  flex-grow: 2;
  justify-content: flex-start;
  overflow: auto;
}

.examination-live-phenotype-panel-container > div:first-child {
  flex-basis: 80%;
}

.examination-live-phenotype-panel-container > div:last-child {
  width: 60%;
}

.examination-live-phenotype-panel-container > div:only-child {
  padding: 0 2rem 0 2rem;
  width: 60%;
}

.examination-live-phenotype-panel-container > div h2 {
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--color-xxxlight-grey);
  margin-top: 0;
}

.examination-live-phenotype-panel-container > div h2 .selected-risk-factor {
  letter-spacing: 0;
  text-transform: none;
  color: var(--color-ocra);
  margin-left: 1em;
}

.examination-live-phenotype-panel-container > div h2 .selected-risk-factor > .icon {
  background: var(--color-ocra);
  color: var(--color-white);
  border-radius: 2em;
  margin-left: 0.5em;
  cursor: pointer;
}

.examination-live-phenotype-panel-container > div h2 .selected-risk-factor > .icon:hover {
  background: var(--color-light-ocra);
}

.examination-live-phenotype-panel-container .unusual-checklist-items li {
  padding: 0.5em 1em;
  background: var(--color-xxlight-grey-40);
  border: var(--border) var(--color-xxlight-grey);
  color: var(--color-ocra);
}

.examination-live-phenotype-panel-slide-risk-factors > div.examination-live-phenotype-panel-norisks {
  background: transparent;
  color: var(--color-xxlight-grey);
  grid-column: span 2;
  text-align: center;
}

.examination-live-phenotype-panel-container > div:only-child .risk-factors {
  max-width: 50%;
}

.examination-live-phenotype-panel-container .risk-factors li {
  padding: 0.5em 1em;
  border: var(--border) var(--color-xxlight-grey);
  font-size: var(--font-size-small);
  display: flex;
  justify-content: space-between;
}

.examination-live-phenotype-panel-container .risk-factors li.is-risky,
.examination-live-phenotype-panel-container .risk-factors li.is-risky label {
  color: var(--color-ocra);
  cursor: pointer;
}

.examination-live-phenotype-panel-container .risk-factors li.is-risky:hover {
  color: var(--color-light-ocra);
}

.examination-live-phenotype-panel-container .visible-risk,
.examination-live-phenotype-panel-container .hidden-risk {
  transition: opacity 0.3s ease-in-out;
}

.examination-live-phenotype-panel-container .hidden-risk {
  opacity: 0.2;
}

.examination-live-phenotype-panel-container .risk-factors li label {
  font-weight: bold;
  min-width: 5rem;
  display: inline-block;
  cursor: default;
}

.examination-live-phenotype-panel-container .risk-factors li .raw-value {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 0.2em;
}

.examination-live-phenotype-panel-container .unusual-checklist-items li:not(:last-child),
.examination-live-phenotype-panel-container .risk-factors li:not(:last-child) {
  border-bottom: 0;
}

.examination-live-phenotype-panel-container .examination-live-phenotype-panel-cta {
  font-size: var(--font-size-small);
  text-align: center;
  margin-top: 1rem;
  color: var(--color-grey);
}

.cant-go-to-medical-history {
  font-size: var(--font-size-small);
  margin-top: 1rem;
  color: var(--color-grey);
  font-style: italic;
}

.examination-live-phenotype-panel-container .unusual-checklist-empty,
.examination-live-phenotype-panel-container .risk-factors-empty {
  text-align: center;
  font-size: var(--font-size-small);
  color: var(--color-xlight-grey);
}

.examination-live-phenotype-panel-risk-phenotype {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.examination-live-phenotype-panel-risk-phenotype > div {
  flex-grow: 2;
}

.examination-live-phenotype-panel-risk-phenotype .outlined {
  border: var(--border) var(--color-xlight-grey);
  border-radius: var(--border-radius-small);
  margin-bottom: 1rem;
}

.examination-live-phenotype-panel-risk-phenotype .entry {
  margin: 0.1rem 0;
}

.examination-live-phenotype-panel-risk-phenotype .entry label {
  min-width: 30%;
  display: inline-flex;
  max-width: 40%;
  align-items: baseline;
  font-size: 0.8em;
  vertical-align: top;
  color: var(--color-black);
  font-weight: normal;
}

.examination-live-phenotype-panel-risk-phenotype .entry label::after {
  content: '';
  height: 0.1em;
  min-width: 1em;
  flex-grow: 2;
  border-bottom: 0.2em dotted var(--color-xlight-grey);
}

.examination-live-phenotype-panel-risk-phenotype .entry > span {
  display: inline-block;
  margin-left: 0.4em;
  max-width: calc(60% - 0.4em);
}

.examination-live-phenotype-panel-risk-phenotype .entry .raw-value {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 0.2em;
}

.examination-live-phenotype-panel-slide-risk-factors {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.examination-live-phenotype-panel-slide-risk-factors > div {
  padding: 1rem;
  background: var(--color-xxlight-grey-80);
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  border-radius: var(--border-radius-small);
}

.examination-live-phenotype-panel-slide-risk-factors .examination-live-phenotype-panel-name {
  color: var(--color-black);
  border-bottom: var(--border) var(--color-xlight-grey);
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.examination-live-phenotype-panel-slide-risk-factors .examination-live-phenotype-panel-slide {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.examination-live-phenotype-panel-slide-risk-factors .examination-live-phenotype-panel-slide > div:first-child {
  max-width: 5rem;
}

.examination-live-phenotype-panel-slide-risk-factors .examination-live-phenotype-panel-checklist {
  flex-grow: 2;
}

.examination-live-phenotype-panel-slide-risk-factors li {
  margin-bottom: 0.5rem;
}

.examination-live-phenotype-panel-container .exam-live-phenotype-stakeholder-section-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: visible;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.examination-live-phenotype-panel-risk-factors > div {
  margin-bottom: 2rem;
}

.examination-live-phenotype-episode-name th {
  text-transform: uppercase;
  color: var(--color-black);
  text-align: left;
  padding: 0.2rem 0.5rem;
  background-color: var(--color-xxlight-grey-40);
}
.examination-live-phenotype-panel-episodes tr[data-is-current='true'] td {
  color: var(--color-black);
}
.examination-live-phenotype-panel-episodes tfoot td {
  text-align: center;
  color: var(--color-light-grey);
  font-size: var(--font-size-small);
}

.examination-live-phenotype-episode-current-exam {
  color: var(--color-black);
}

.patient-notes-title {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 0.4rem;
}

.patient-notes-title > h2 {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.patient-notes-root {
  position: relative;
  z-index: 10;
}

.patient-notes-toggle-container {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  margin-top: 0.2rem;
}
.patient-notes-toggle-container > span {
  font-size: var(--font-size-xsmall);
}
