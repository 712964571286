.button-group {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.button-group.disabled {
  opacity: 0.5;
}
.button-group label {
  text-align: center;
  display: block;
  margin-bottom: 0.3em;
}
.button-group-options {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-radius: var(--border-radius-small);
  overflow: hidden;
}
.button-group .option {
  display: inline-block;
  background: var(--color-xxlight-grey);
  box-shadow: calc(var(--border-size) * -1) 0 0 0 var(--color-grey);
  padding: 0.7em 1.2em;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}
.button-group.disabled .option {
  cursor: default;
}

.button-group.wrap .button-group-options {
  flex-wrap: wrap;
}
.button-group.wrap .button-group-options .option {
  box-shadow: calc(var(--border-size) * -1) 0 0 0 var(--color-grey),
    0 calc(var(--border-size) * -1) 0 0 var(--color-grey);
  flex-grow: 2;
}

.button-group.size-full-width {
  width: 100%;
}
.button-group.size-full-width .button-group-options {
  display: flex;
}
.button-group.size-full-width .button-group-options .option {
  flex-grow: 2;
}
.button-group.size-compact .option {
  padding: 0.39em 1.2em;
}
.button-group .option:last-of-type {
  border-right: 0;
}
.button-group .option.selected {
  background: var(--color-blue);
  color: var(--color-common-black);
}
.button-group.color-ocra.color-ocra .option.selected {
  background: var(--color-ocra) !important;
}
.button-group .icon {
  transform: scale(1.3);
}
.button-group .icon + .button-group_label {
  margin-left: 0.5em;
}
.button-group .button-group_label + .icon {
  margin-left: 0.5em;
}

.button-group.variant-outline .button-group-options .option {
  background: transparent;
  color: var(--color-blue);
}
.button-group.variant-outline .button-group-options .option.selected {
  color: var(--color-white);
  background: var(--color-blue);
}
.button-group.variant-outline .button-group-options::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--border-thick) var(--color-blue);
  border-radius: var(--border-radius-small);
  pointer-events: none;
}
.button-group.variant-outline:hover .button-group-options {
  color: var(--color-light-blue);
}

.button-group.variant-outline.color-ocra .button-group-options::after,
.button-group.variant-outline.color-ocra .option {
  border-color: var(--color-ocra);
}
.button-group.variant-outline.color-ocra .option {
  color: var(--color-ocra);
}
.button-group.variant-outline.color-ocra .option.selected {
  background-color: var(--color-ocra);
}

.button-group.theme-light .option {
  color: var(--color-common-white) !important;
  background: var(--color-common-blue) !important;
}
.button-group.theme-light .option.selected {
  background: var(--color-common-ocra) !important;
}
.button-group.theme-light .option:hover {
  background-color: var(--color-common-light-blue) !important;
}
.button-group.variant-outline.theme-light .option:hover {
  color: var(--color-common-light-blue) !important;
}
.button-group.variant-outline.theme-light .option {
  color: var(--color-common-blue) !important;
  background: transparent !important;
}
.button-group.variant-outline.theme-light .button-group-options::after {
  color: var(--color-common-blue) !important;
}
.button-group.variant-outline.theme-light .button-group-options::after {
  border-color: var(--color-common-blue) !important;
}

.button-group.theme-grey .option {
  color: var(--color-blue) !important;
  background: var(--color-xlight-grey) !important;
}
.button-group.theme-grey .option.selected {
  color: var(--color-common-black) !important;
  background: var(--color-blue) !important;
}
