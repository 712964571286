.exam-report-dialog {
  position: fixed;
  top: 3rem;
  left: 3rem;
  width: calc(100% - 6rem);
  height: calc(100svh - 6rem);
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  z-index: 10000;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.exam-report-dialog .direction-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.exam-report-dialog .direction-column small {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
}
.exam-report-dialog h3 {
  text-align: center;
}
.exam-report-dialog textarea::placeholder {
  color: var(--color-dark-grey-80);
}

.exam-report-dialog-bottom-bar {
  display: flex;
  align-items: center;
}
.exam-report-dialog-bottom-bar .error {
  font-size: var(--font-size-small);
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  height: 3rem;
}

.exam-report-dialog-floating-bar {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  background: var(--color-xxlight-grey);
  border-radius: var(--border-radius);
  box-shadow: var(--common-box-shadow-thin);
}

:is(.exam-report-dialog-bottom-bar, .exam-report-dialog-floating-bar) .buttons {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 0.5rem;
}

.event-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  border-bottom: var(--border) var(--color-xxlight-grey);
  margin-bottom: 0.75em;
  gap: 0.1em;
}

.event-item .event-item-comment {
  padding: 0.5em;
  background-color: var(--color-grey-40);
  width: 100%;
  border-radius: var(--border-radius-small);
}

.event-item .event-item-timestamp {
  font-size: 0.75em;
  font-style: italic;
}

.event-item .user-name {
  font-weight: bold;
}

.dialog-done-content.icon {
  font-size: 6em;
}
.dialog-done-content.message p.message-centred {
  text-align: center;
}
.dialog-done-content.dialog-done-content-button {
  font-size: var(--font-size-small);
  height: 4em;
}
.dialog-done-content.dialog-done-content-button button {
  margin: 0.5em;
}

.exam-report-dialog-done {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  width: 100%;
  height: 100%;
}

.exam-report-dialog-section-hr {
  background: var(--color-grey);
}

.exam-report-dialog-section-title {
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  letter-spacing: 0.2em;
}

.exam-report-dialog-stakeholder-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: visible;
}

.exam-report-dialog .exam-report-dialog-header {
  display: flex;
  gap: 1em;
  align-items: center;
  border-bottom: var(--border) var(--color-grey-40);
  padding: 0.5em 1em;
}
.exam-report-dialog .exam-report-dialog-header_title {
  flex-grow: 2;
  display: flex;
  gap: 1em;
  align-items: center;
}

.exam-report-dialog .exam-report-dialog-header h2 {
  margin: 0;
  font-size: var(--font-size-medium);
  background-color: transparent;
}
.exam-report-dialog h2 em {
  font-weight: normal;
  font-style: normal;
}

.exam-report-dialog .exam-report-dialog-body {
  display: flex;
  gap: 1rem;
  flex-grow: 2;
  overflow: hidden;
  padding: 1rem;
}
.exam-report-dialog .exam-report-dialog-body.column-direction,
.exam-report-dialog .exam-report-dialog-body > .exam-report-dialog-comment.column-direction {
  display: grid;
  grid-template-rows: 1fr auto;
  flex-direction: column;
}
.exam-report-dialog .exam-report-dialog-body > div {
  flex: 1;
  border: var(--border) var(--color-xlight-grey);
  padding: 0.5rem 1rem 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  scrollbar-width: auto;
}

.exam-report-dialog .exam-report-dialog-stakeholder-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: visible;
  padding: 1rem 0;
}

.exam-report-dialog .exam-report-dialog-cta-add {
  text-align: center;
  padding: 1em;
  font-size: var(--font-size-small);
}

.exam-report-dialog .exam-report-dialog-tempcard {
  background: transparent;
  border: var(--border-thick-size) dashed var(--color-grey);
  overflow: visible;
}

.exam-report-dialog .exam-report-dialog-body > .exam-report-dialog-comment {
  flex: 0 !important;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
}
.exam-report-dialog .exam-report-dialog-body > .exam-report-dialog-comment button {
  white-space: nowrap;
}

.exam-report-dialog .exam-report-dialog-body > .exam-report-dialog_submit {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exam-report-dialog .exam-report-dialog_body > .exam-report-dialog-comment {
  flex: 0 !important;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
  border: var(--border) var(--color-xlight-grey);
  margin: 0 1rem 1rem;
}
.exam-report-dialog .exam-report-dialog_body > .exam-report-dialog-comment h2 {
  font-size: var(--font-size-medium);
  margin: 0;
}
.exam-report-dialog .exam-report-dialog_body > .exam-report-dialog-comment .buttons {
  padding: 0;
  margin-top: 1rem;
}

.exam-report-dialog .exam-report-dialog_body > .exam-report-dialog-comment button {
  white-space: nowrap;
}

.exam-report-dialog .exam-report-dialog_body > .exam-report-dialog_submit {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exam-report-dialog .exam-report-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 50rem;
}

.exam-report-dialog-print-preview {
  position: relative;
  min-width: calc(100% + 2rem);
  max-width: calc(100% + 2rem);
  min-height: calc(100% + 2rem);
  max-height: calc(100% + 2rem);
  top: -1rem;
  left: -1rem;
}
