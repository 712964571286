.edit-mode-dialog-root {
  position: absolute;
  bottom: 1.125rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 35rem;
  padding: 0.8rem 1rem;
  gap: 0.6rem;
  border-radius: var(--border-radius-small);
  background: var(--color-common-xlight-blue);
  color: var(--color-common-black);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-mode-dialog-title {
  font-size: var(--font-size-medium);
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.2rem;
}

.edit-mode-dialog-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-mode-dialog-button-outline {
  background: transparent;
  color: var(--color-common-black);
  border: var(--border) var(--color-common-black);
}

.edit-mode-dialog-button-fill {
  background-color: var(--color-common-blue);
  color: white;
}

.edit-mode-dialog-button-fill:hover {
  background-color: var(--color-common-blue);
}

.edit-mode-dialog-buttons-right {
  display: flex;
  gap: 0.8rem;
}
