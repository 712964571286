.cert-id-input-type-wrapper {
  height: var(--font-size-xxxlarge);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  border-radius: var(--border-radius-small);
  background-color: var(--color-xlight-grey);
}

.cert-id-input-flex-wrapper {
  display: flex;
  gap: var(--font-size-xsmall);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.cert-id-type-wrapper {
  height: var(--font-size-xxxlarge);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  border-radius: var(--border-radius-small);
  background-color: var(--color-xlight-grey);
  flex-wrap: nowrap;
}

.cert-id-type {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s;
  justify-content: center;
  width: 7rem;
}

.cert-id-type.selected {
  background-color: var(--color-blue);
  color: var(--color-xxxlight-grey);
}
