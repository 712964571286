.select-exam-to-start-dialog {
  display: flex;
  justify-content: center;
}
.select-exam-to-start-dialog .button {
  width: 100%;
  margin: 0.2rem 0;
}
.select-exam-to-start-dialog small {
  display: block;
}
.select-exam-to-start-dialog .breaking-cta {
  display: flex;
  align-items: flex-end;
}
.select-exam-to-start-dialog .breaking-cta > div {
  margin: 0 0.5rem 0 0;
}

.select-exam-to-start-dialog-container {
  max-height: 20rem;
  overflow: scroll;
}

.select-exam-to-start-dialog-container .draft-list-container {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out;
}
.select-exam-to-start-dialog-container .draft-list-container h4 {
  text-align: left;
}
.select-exam-to-start-dialog-container .draft-list {
  display: flex;
  flex-wrap: wrap;
}
.select-exam-to-start-dialog-container .draft-list > div {
  background: var(--color-xxxlight-grey-40);
  border-radius: var(--border-radius-small);
  max-width: calc(33% - 1rem);
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  text-align: left;
  cursor: pointer;
}
.select-exam-to-start-dialog-container .draft-list > div:hover {
  background: var(--color-xlight-grey-40);
}
.select-exam-to-start-dialog-container .draft-list > div .draft-name {
  color: var(--color-black);
}
.select-exam-to-start-dialog-container .draft-list > div .draft-date {
  font-size: var(--font-size-small);
}
.select-exam-to-start-dialog-container .draft-list > div .draft-cta {
  font-size: var(--font-size-xsmall);
}
