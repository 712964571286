.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  vertical-align: middle;
}
.icon svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}
