.exam-card-container {
  padding: 1rem 1.5rem;
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}
.exam-card-container:hover {
  background: var(--color-xxlight-grey);
}
.exam-card-container > .name {
  color: var(--color-black);
}
.exam-card-container > .card-info {
  font-size: var(--font-size-small);
  line-height: var(--font-size-small);
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  white-space: nowrap;
  flex-grow: 2;
}
.exam-card-container > .card-info .trimester {
  white-space: normal;
  flex-grow: 2;
}
.exam-card-container > .card-info .icon {
  color: var(--color-light-grey);
}
.exam-card-container > .cta {
  font-size: var(--font-size-xsmall);
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}
.exam-card-container:hover .cta-resume {
  color: var(--color-black);
}
.exam-card-container > .cta .button.cta-delete {
  background: transparent;
}
.exam-card-container > .cta .button.cta-delete:hover {
  background: var(--color-red);
  border-color: var(--color-red);
  color: var(--color-black);
}

.exam-card-container .exam-card-risk-factors {
  padding: 0.5rem 0;
  flex-grow: 2;
}
.exam-card-container .exam-card-risk-factors ul {
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-medium);
  list-style: none;
  padding: 0;
}
.exam-card-container .exam-card-risk-factors ul li {
  margin: 0;
  padding: 0;
  transition: color 0.3s ease-in-out;
}
.exam-card-container .exam-card-risk-factors ul li label {
  min-width: 30%;
  display: inline-flex;
  font-weight: bold;
  max-width: 40%;
  line-height: var(--font-size-medium);
  align-items: baseline;
  font-size: 0.8em;
  vertical-align: top;
}
.exam-card-container .exam-card-risk-factors ul li label::after {
  content: '';
  height: 0.1em;
  min-width: 1em;
  flex-grow: 2;
  border-bottom: 0.2em dotted var(--color-xlight-grey);
}
.exam-card-container .exam-card-risk-factors ul li span {
  display: inline-block;
  line-height: var(--font-size-small);
  margin-top: 0;
  margin-left: 0.4em;
  max-width: calc(60% - 0.4em);
}
.exam-card-container .exam-card-risk-factors ul li .raw-value {
  border: var(--border);
  border-radius: var(--border-radius-small);
  padding: 0 0.2em;
  margin: 0;
}
.exam-card-container .exam-card-risk-factors ul li.teratogenic-risks {
  display: flex;
}
.exam-card-container .exam-card-risk-factors ul li.teratogenic-risks span {
  flex-grow: 2;
}
.exam-card-container .exam-card-risk-factors ul li.teratogenic-risks span span {
  display: block;
}
