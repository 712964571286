.textarea {
  display: inline-block;
  position: relative;
}

.textarea > label {
  text-align: center;
  display: block;
  margin-bottom: 0.3em;
}

.textarea .textarea-field {
  position: relative;
}

.textarea.has-icon textarea {
  padding-left: 2.2em;
}

.textarea.has-icon .icon {
  position: absolute;
  top: 0.6em;
  left: 0.6em;
}

.textarea.error .icon {
  color: var(--color-xlight-red) !important;
}

.textarea.error textarea {
  border-color: var(--color-xlight-red) !important;
  border-width: var(--border-thick-size) !important;
}

.textarea.highlight textarea {
  border-color: var(--color-ocra);
  border-width: var(--border-thick-size);
}

.textarea.full-width {
  display: block;
  width: 100%;
}
.textarea.full-width textarea {
  width: 100%;
}
.textarea.full-width .textarea-field {
  display: block;
  width: 100%;
}
