.hidden {
  display: none;
}

.examination-live-phenotype-panel-documents-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-bottom: 0 !important;
}

.examination-live-phenotype-panel-documents-title {
  padding-bottom: 0;
  margin-bottom: 0;
}

.examination-live-phenotype-panel-documents-header-actions {
  font-size: 1.3em;
}

.examination-live-phenotype-panel-documents-episode {
  margin-bottom: 0.5rem;
  border-bottom: var(--border) var(--color-xlight-grey);
  padding-bottom: 0.2em;
}

.examination-live-phenotype-panel-documents-episode-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.examination-live-phenotype-panel-documents-episode-name {
  font-size: var(--font-size-small);
  margin-left: 0.5rem;
}

.examination-live-phenotype-panel-documents-episode-attachments {
  font-size: var(--font-size-small);
}

.examination-live-phenotype-panel-documents-episode-attachment {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;
}

.examination-live-phenotype-panel-documents-episode-attachment-container {
  overflow: hidden;
}

.examination-live-phenotype-panel-documents-episode-attachment-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.examination-live-phenotype-panel-documents-episode-attachment-icon {
  vertical-align: middle;
}

.examination-live-phenotype-panel-documents-episode-attachment-name {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  font-weight: normal;
  display: flex;
}

.examination-live-phenotype-panel-documents-episode-attachment-name .filename__basename {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.examination-live-phenotype-panel-documents-episode-attachment-date {
  text-align: center;
  color: var(--color-light-grey);
  font-size: var(--font-size-xsmall);
}

.exam-report-dialog-print-image-preview {
  margin: auto;
  height: 100%;
  object-fit: contain;
}

.gray-background {
  background: #444;
}
