.left-navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;
  padding: 1rem;
  line-height: 1;
  color: var(--color-common-blue);
  font-size: var(--font-size-large);
}
.left-navbar > .toggle-menu {
  position: relative;
  z-index: 1030;
  cursor: pointer;
  font-size: var(--font-size-xlarge);
  color: var(--color-blue);
}
.theme-dark .left-navbar > .toggle-menu {
  color: var(--color-black);
}
.left-navbar > .toggle-menu .icon.left {
  color: var(--color-common-blue) !important;
}

.left-navbar > .toggle-menu.go-back .icon.left {
  color: var(--color-black) !important;
}

.left-navbar > .icon.close:hover,
.left-navbar .icon.settings:hover {
  color: var(--color-common-light-blue);
}

.left-navbar h3 {
  font-size: var(--font-size-small);
  margin: 0.2em 0;
  cursor: pointer;
}
.left-navbar h3:hover {
  color: var(--color-common-light-blue);
}
.left-navbar h3 .icon {
  color: var(--color-common-grey);
  margin-right: 0.5em;
}

.left-navbar .toggle-menu .update-available-indicator {
  border-radius: 50%;
  height: 0.6rem;
  width: 0.6rem;
  background: var(--color-ocra);
  position: absolute;
  top: 0.1rem;
  right: -0.2rem;
  z-index: 1010;
  border: var(--border-thick) var(--color-xxxlight-grey);
}
.left-navbar.open .toggle-menu .update-available-indicator {
  display: none;
}

.left-navbar .left-navbar-main {
  position: fixed;
  top: 0;
  left: calc(-1 * var(--left-navbar-width));
  width: var(--left-navbar-width);
  height: 100%;
  background: var(--color-common-light-grey);
  z-index: 1020;
  transition: left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  padding: 3.5rem 1rem 0;
  font-size: var(--font-size-large);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.left-navbar .left-navbar-main.open {
  left: 0;
  box-shadow: var(--common-box-shadow);
  z-index: 1020;
}

.left-navbar .left-navbar-main.open > .update-available-notif {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.5em;
  padding: 1rem;
  z-index: 1020;
}

.left-navbar-user > .left-navbar-user-bottom .footer > .update-available-notif > div {
  cursor: text;
  font-size: 0.75rem;
  margin-top: 0.6rem;
  color: var(--color-xlight-blue);
}

.left-navbar .left-navbar-main ul {
  list-style: none;
  padding: 0;
}

.left-navbar .left-navbar-main ul li {
  margin: 0;
  padding: 0.6em 0;
  border-bottom: var(--border) var(--color-common-grey);
  position: relative;
  cursor: pointer;
  display: flex;
}
.left-navbar .left-navbar-main ul li:last-child {
  border-bottom: 0;
}
.left-navbar .left-navbar-main ul li:hover a,
.left-navbar .left-navbar-main ul li:hover .icon {
  color: var(--color-common-light-blue);
}
.left-navbar .left-navbar-main ul li .icon {
  color: var(--color-common-grey);
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}
.left-navbar .left-navbar-main ul li:hover .icon {
  transform: translateX(0.2em);
}

.left-navbar .left-navbar-main a {
  color: var(--color-common-blue);
  flex-grow: 2;
  transition: color 0.3s ease-in-out;
}

.left-navbar .left-navbar-current-examination {
  background: var(--color-common-white);
  border-radius: var(--border-radius-small);
  padding: 0.4rem;
  text-align: center;
  box-shadow: var(--common-box-shadow-thin-blue);
  font-size: var(--font-size-small);
}
.left-nav-bar-new-appointment button {
  font-size: 1rem;
  width: 100%;
  margin: 0.5em 0 0.5em 0 !important;
}
.left-navbar-new-examination {
  font-size: var(--font-size-small);
  padding: 0 0.4rem 0.4rem;
  border-radius: var(--border-radius-small);
  margin: 0.8rem 0;
  text-align: center;
}
.left-navbar-new-examination.highlight {
  box-shadow: var(--common-box-shadow-thin-blue);
  background: var(--color-common-white);
  padding: 0.5rem 0;
}
.left-navbar-new-examination h3 {
  text-align: center;
  margin: 0.5rem 0 0 0;
  font-weight: normal;
}
.left-navbar .left-navbar-current-examination button,
.left-navbar .left-navbar-new-examination button {
  width: 100%;
  margin: 0.5em 0 0 0 !important;
}
.left-navbar-current-examination button {
  justify-content: left;
}
.left-navbar-current-examination .group {
  display: flex;
}
.left-navbar-current-examination .group button {
  width: auto;
}
.left-navbar-current-examination .group button:first-child {
  flex-grow: 2;
  margin-right: 0.4rem !important;
}
.left-navbar .left-navbar-separator {
  flex-grow: 2;
}

.left-navbar .left-navbar-selector {
  font-size: var(--font-size-small);
  margin: 0.5rem 0;
}

.left-navbar .left-navbar-user-preview {
  font-size: var(--font-size-small);
  text-align: center;
  padding: 1rem;
  position: relative;
  cursor: pointer;
}
.left-navbar .left-navbar-user-preview:hover {
  color: var(--color-common-light-blue);
}
.left-navbar .left-navbar-user-preview::before {
  content: '';
  position: absolute;
  top: 0;
  left: 30%;
  width: 40%;
  height: 0;
  border: var(--border) var(--color-common-grey);
}
.left-navbar-username {
  font-weight: bold;
}
.left-navbar-sitename {
  margin-top: 0.3rem;
}
.left-navbar-preferences {
  font-size: var(--font-size-xsmall);
  margin-top: 0.8rem;
}
.left-navbar .left-navbar-user-preview .logout:hover {
  color: var(--color-common-light-blue) !important;
  cursor: pointer;
}
.left-navbar .footer {
  font-size: var(--font-size-xsmall);
  margin: 1rem 0;
}
.left-navbar .footer span {
  margin: 0.3rem;
  white-space: nowrap;
}
.site-change-dropdown {
  color: var(--color-common-blue);
  border: var(--border) var(--color-common-blue);
}

.left-navbar-user {
  position: fixed;
  top: 0;
  left: calc(var(--left-navbar-width) * -1);
  width: var(--left-navbar-width);
  height: 100%;
  background: var(--color-common-grey);
  box-shadow: var(--common-box-shadow-thin);
  padding: 1rem;
  z-index: -10;
  text-align: center;
  font-size: var(--font-size-medium);
  transition: left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.left-navbar-user.open {
  left: var(--left-navbar-width);
}
.left-navbar-user .left-navbar-user-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 100%;
  text-align: right;
  padding: 0.5rem !important;
  cursor: pointer;
  font-size: var(--font-size-large);
}
.left-navbar-user .left-navbar-user-close:hover {
  color: var(--color-common-light-blue);
}
.left-navbar-user-top {
  flex-grow: 2;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.left-navbar-user > div.settings,
.left-navbar-user > div.switch-user,
.left-navbar-user > div.logout {
  cursor: pointer;
}
.left-navbar-user > div.settings:hover,
.left-navbar-user > div.switch-user:hover,
.left-navbar-user > div.logout:hover {
  color: var(--color-common-light-blue);
}
.left-navbar-user > .left-navbar-user-bottom .footer {
  margin-bottom: 0;
}
.left-navbar-user .left-navbar-user-avatar {
  font-size: var(--font-size-xxlarge);
  background: var(--color-common-xlight-grey);
  border-radius: 50%;
  padding: 0.1em;
  margin-bottom: 0rem !important;
}
.left-navbar-user .left-navbar-user-top > div {
  margin: 0.5rem 0;
}
.left-navbar-user .left-navbar-user-top > div.settings:hover,
.left-navbar-user .left-navbar-user-top > div.switch-user:hover,
.left-navbar-user .left-navbar-user-top > div.logout:hover {
  cursor: pointer;
  color: var(--color-common-light-blue);
}
.left-navbar-user > .left-navbar-user-bottom img {
  display: block;
  width: 100%;
  margin: 0.2rem 0 0 0;
}
.left-navbar-user > .left-navbar-user-bottom .footer > div {
  margin-bottom: 0.7rem;
  cursor: pointer;
}

.left-navbar-panel {
  position: fixed;
  left: calc(var(--left-navbar-width) * 2 - 100vw);
  width: calc(100vw - var(--left-navbar-width) * 2);
  background: var(--color-common-white);
  top: 0;
  height: 100%;
  overflow: auto;
  padding: 3rem 0 0;
  transition: left 0.5s ease-in-out;
  z-index: -20;
}
.left-navbar-panel.open {
  left: calc(var(--left-navbar-width) * 2);
}
.left-navbar-panel > .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: var(--font-size-large);
  z-index: 10;
  cursor: pointer;
  color: var(--color-common-blue);
}
.left-navbar-panel > .close:hover {
  color: var(--color-common-light-blue);
}
.left-navbar-panel iframe {
  width: 100%;
  height: 100%;
}

.left-navbar-user .multiscreen {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.left-navbar-user .left-navbar-regulatory-labels {
  position: fixed;
  right: -100svw;
  bottom: 0;
  padding: 1rem;
  width: clamp(30rem, calc((100svw - var(--left-navbar-width) * 2) / 2), calc((100svw - var(--left-navbar-width) * 2)));
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: right 0.3s ease-in-out;
}
.left-navbar-user .left-navbar-regulatory-labels.open {
  right: 0;
}
.left-navbar-user .left-navbar-regulatory-labels > div {
  flex: 1;
  color: var(--color-common-blue);
  font-size: var(--font-size-medium);
  line-height: var(--font-size-large);
  background: var(--color-common-grey);
  border-radius: var(--border-radius-small);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.left-navbar-user .regulatory-label {
  border: var(--border);
  padding: 0.5rem;
  margin-top: 0.2rem;
}

.left-navbar-user .regulatory-label.ce-mark {
  text-align: center;
}

.left-navbar-user .regulatory-label.br-mark {
  font-size: var(--font-size-xxsmall);
  text-align: left;
}

.left-navbar-user .regulatory-links {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-content: center;
  justify-content: center;
  white-space: nowrap;
  flex-wrap: wrap;
}
