.drag-and-drop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  z-index: 1000;
  pointer-events: none;
  transition: background 0.2s ease;
}

.drag-and-drop-overlay.dragging {
  background: rgba(0, 0, 0, 0.5);
}

.drag-and-drop-message {
  color: white;
  font-size: 24px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.dragging > .drag-and-drop-message {
  opacity: 1;
}
