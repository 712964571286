.search-bar_wrapper {
  position: relative;
}

.search-bar_inline-cta {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.3em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.search-bar_clean {
  display: block;
  line-height: 1;
  background: var(--color-light-grey);
  color: var(--color-white);
  border-radius: 1em;
  cursor: pointer;
}
.search-bar_clean:hover {
  background: var(--color-grey);
}
.search-bar_quick-filters {
  display: flex;
  gap: 0.2em;
}
.search-bar_results {
  font-size: var(--font-size-small);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-small);
  padding: 0.5em 0;
  background: var(--color-white);
}

.search-bar_results li {
  padding: 0.2rem 0.8rem;
  cursor: pointer;
  border-bottom: var(--border) var(--color-xxlight-grey);
}
.search-bar_results li:hover {
  background: var(--color-xxxlight-grey);
}
.search-bar_results li b {
  text-decoration: underline;
}
.search-bar_results li small {
  color: var(--color-blue-40);
}
.search-bar_results li .search-bar_tag {
  white-space: nowrap;
}

.search-bar_results li.type-heading {
  padding: 0.5rem 0.8rem;
  background-color: var(--color-white);
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: var(--font-size-small);
  justify-content: space-between;
  cursor: default;
}

.search-bar_results li.type-warning {
  background-color: var(--color-xxlight-grey);
  border-radius: 3em;
  padding: 0 0.3rem;
  margin: 0 0.3rem;
  font-size: var(--font-size-xsmall);
  cursor: default;
}

.search-bar_results .search-bar_result {
  display: flex;
  justify-content: space-between;
}
.search-bar_results .search-bar_result_source {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  font-size: var(--font-size-xsmall);
}
