.anomaly-line {
  list-style: none;
  margin: 0;
  cursor: pointer;
  color: var(--color-blue);
  background: var(--color-white);
  padding: 0;
  border: var(--border) transparent;
  border-bottom: var(--border) var(--color-xxlight-grey);
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out, color 0.4s linear;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.anomaly-line.disabled {
  opacity: 0;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out, opacity 0.8s ease-in-out, color 0.4s linear;
  pointer-events: none;
}
.anomaly-line.maybe {
  color: var(--color-blue);
}
.anomaly-line.no {
  color: var(--color-blue);
}
.anomaly-line.unknown {
  color: var(--color-grey);
}

.anomaly-line:last-of-type {
  border-bottom: 0;
}
.anomaly-line:hover {
  color: var(--color-light-blue);
}

.anomaly-line .more {
  white-space: nowrap;
}
.anomaly-line .more .icon {
  transition: transform 0.3s ease-in-out;
}
.anomaly-line.open .more .icon {
  transform: rotate(180deg);
}

.anomaly-line .more .status-preview {
  text-transform: uppercase;
  font-size: var(--font-size-xxsmall);
}

.anomaly-line.open {
  border: var(--border);
  box-shadow: var(--box-inset-shadow-thin);
  z-index: 10;
  position: relative;
  box-shadow: var(--box-shadow-blue);
}
.anomaly-line.yes.open {
  border-color: var(--color-ocra);
  box-shadow: var(--box-shadow-ocra);
}

.anomaly-line > div:first-child {
  padding: 0.8em 1em;
}
.anomaly-line > div {
  display: flex;
  align-items: center;
  flex-grow: 2;
  gap: 1rem;
}

.anomaly-line .anomaly-name {
  flex-grow: 2;
}
.anomaly-line .anomaly-name span.suffix {
  color: var(--color-dark-grey);
}
.anomaly-line .anomaly-name span.suffix::before {
  content: '\0020\2013\0020';
}
.anomaly-line .disease-name {
  font-size: var(--font-size-xsmall);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* anomaly values */

.anomaly-line.no > div {
  color: var(--color-blue);
}

.anomaly-line.yes > div {
  color: var(--color-ocra);
}

.anomaly-line .anomaly-details {
  height: 0;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  flex-direction: column;
}
.anomaly-line.open .anomaly-details {
  height: auto;
}
.anomaly-line .anomaly-details > ul {
  font-size: var(--font-size-small);
  flex-grow: 2;
  overflow: auto;
  text-align: center;
  width: 100%;
  max-height: 6rem;
  list-style: none;
  padding: 1rem;
  margin: 0;
}
.anomaly-line .anomaly-details > ul li {
  display: inline-block;
  margin: 0.3rem;
  padding: 0.1rem 0.3rem;
  white-space: nowrap;
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
}
.anomaly-details-change-status {
  width: 100%;
  display: flex;
  padding: 0rem 1rem 1rem;
  font-size: var(--font-size-small);
}
.anomaly-details-change-status button {
  margin: 0 0.5rem 0 0;
  flex-grow: 2;
}
.anomaly-details-change-status button:last-child {
  margin-right: 0;
}
.anomaly-details-change-status button.selected {
  background: var(--color-ocra);
}
.anomaly-details-change-status button.outline.selected {
  color: var(--color-common-black);
  background-color: var(--color-ocra);
  border-color: var(--color-ocra);
}

.anomaly-line .synonym {
  font-size: 0.8em;
  color: var(--color-light-grey);
  display: block;
  line-height: 1em;
}

.anomaly-line .highlight {
  font-weight: bold;
  border-bottom: var(--border) var(--color-light-grey);
}

.anomaly-status {
  font-size: 0.6em;
  margin-left: 1em;
  background: var(--color-blue);
  border-radius: var(--border-radius-large);
  padding: 0.4em 0.6em;
  color: var(--color-white);
  display: inline-block;
  line-height: 0.6;
  white-space: nowrap;
  vertical-align: middle;
}
.anomaly-status.unknown {
  background: var(--color-grey);
}
.anomaly-status.yes {
  background: var(--color-ocra);
}

.anomaly-line .anomaly-cta {
  color: var(--color-xlight-grey);
  margin-right: 0.5em;
  font-size: 0.8em;
  white-space: nowrap;
}
