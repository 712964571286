.simulator-page-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  font-size: var(--font-size-medium);
}
.simulator-page-container > .video {
  flex-grow: 2;
  text-align: center;
  position: relative;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.simulator-page-container .capture-canvas {
  width: auto;
  height: 100%;
  cursor: grab;
}

.simulator-page-container .capture-canvas.grabbing {
  cursor: grabbing;
}

.simulator-page-container .video-container {
  display: none;
}

.simulator-page-container .footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: var(--color-blue);
  padding: 0.5rem;
}
.simulator-page-container .instructions {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xlarge);
  line-height: var(--font-size-small);
  gap: 0.5rem;
}
.simulator-page-container .instructions .item-group {
  display: flex;
  gap: 0.5rem;
}
.simulator-page-container .instructions .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3em;
  min-height: 3em;
  text-align: center;
  border: var(--border) var(--color-xxlight-grey);
  padding: 0.5em;
  transition: border-color 0.2s, color 0.2s;
}
.simulator-page-container .instructions .item:hover {
  color: var(--color-black);
}
.simulator-page-container .instructions .item.selected {
  color: var(--color-black);
  border-color: var(--color-black);
}
.simulator-page-container .instructions .item > .label {
  font-size: var(--font-size-xsmall);
}

.simulator-page-container .fetus {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.simulator-page-container .fetus .fetus-controller-container {
  width: 2.5rem;
  margin: 0 0.5rem;
}

.simulator-page-container .fetus .techno-list {
  display: flex;
  align-items: flex-start;
}

.simulator-page-container .fetus .techno {
  font-size: var(--font-size-xsmall);
  padding: 0.2rem;
  margin: 0.5em;
  border: var(--border);
  border-radius: var(--border-radius-small);
  cursor: pointer;
}

.simulator-page-container .fetus .techno.selected {
  color: var(--color-black);
}

.simulator-page-container .annotations {
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  overflow: hidden;
}

.simulator-page-container .annotations-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  overflow: auto;
  max-height: 10rem;
}

.simulator-page-container .annotations-item {
  padding: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  border-radius: var(--border-radius-small);
  background-color: var(--color-xxxlight-grey);
}

.simulator-page-container .annotations-item .close-icon {
  border-left: 0.05rem solid var(--color-blue);
  margin: 0.1rem;
  margin-left: 0.5rem;
  padding-left: 0.2rem;
  cursor: pointer;
}
.simulator-page-container .annotations-item .close-icon:hover {
  color: var(--color-black);
  border-color: var(--color-black);
}

.simulator-page-container .progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.5rem;
  background: var(--color-xxxlight-grey);
  cursor: pointer;
}
.simulator-page-container .progress .progress-bar {
  position: relative;
  height: 100%;
  width: calc(var(--percent) * 1%);
  background: var(--color-blue);
  pointer-events: none;
}

@media (max-aspect-ratio: 5/3) {
  .simulator-page-container {
    flex-direction: column;
  }
  .simulator-page-container .footer {
    flex-direction: row;
    gap: 1rem;
  }
  .simulator-page-container .instructions {
    flex-direction: row;
  }
  .simulator-page-container .annotations-list {
    flex-direction: row;
  }
}
@media (max-aspect-ratio: 6/5) {
  .capture-canvas {
    width: 100%;
    height: auto;
  }
}
