html {
  font-size: clamp(10pt, 1.5vw, 14pt);
  overscroll-behavior: none;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}
*:not(input) {
  -webkit-user-select: none !important;
  user-select: none !important;
}
*:focus {
  outline: none !important;
}

::selection {
  color: var(--color-xlight-grey);
  background: var(--color-black);
  outline: none !important;
}

/* hide sidebars everywhere */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  display: none;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  font-family: 'Metropolis', sans-serif;

  --color-black: #051928;
  --color-dark-grey: #68686a;
  --color-grey: #bcc8d3;
  --color-light-grey: #d6dfe6;
  --color-xlight-grey: #eaecf0;
  --color-xxlight-grey: #f1f3f5;
  --color-xxxlight-grey: #f6f7f8;
  --color-white: #fff;

  --color-dark-ocra: #c59b26;
  --color-ocra: #e9bf50;
  --color-light-ocra: #f4e0ab;

  --color-dark-blue: #115c96;
  --color-blue: #207cc2;
  --color-light-blue: #68b1e9;
  --color-light-blue-50: #68b1e950;
  --color-xlight-blue: #acd7f8;
  --color-xxxdark-blue: #061927;

  --color-red: #ca0000;
  --color-yellow: #caca00;
  --color-green: #00ca00;

  --color-semitransparent: #00000080;

  --color-black-40: #05192840;
  --color-dark-grey-40: #68686a40;
  --color-grey-40: #bcc8d340;
  --color-light-grey-40: #d6dfe640;
  --color-xlight-grey-40: #eaecf040;
  --color-xxlight-grey-40: #f1f3f540;
  --color-xxxlight-grey-40: #f6f7f840;
  --color-white-40: #fff4;

  --color-dark-ocra-40: #c59b2640;
  --color-ocra-40: #e9bf5040;
  --color-light-ocra-40: #f4e0ab40;

  --color-dark-blue-40: #115c9640;
  --color-blue-40: #207cc240;
  --color-light-blue-40: #68b1e940;
  --color-xlight-blue-40: #acd7f840;

  --color-black-80: #05192880;
  --color-dark-grey-80: #68686a80;
  --color-grey-80: #bcc8d380;
  --color-light-grey-80: #d6dfe680;
  --color-xlight-grey-80: #eaecf080;
  --color-xxlight-grey-80: #f1f3f580;
  --color-xxxlight-grey-80: #f6f7f880;
  --color-white-80: #fff8;

  --color-red: #ca0000;
  --color-light-red: #ff0000;
  --color-xlight-red: #ce6361;
  --color-yellow: #caca00;
  --color-light-yellow: #ffff00;
  --color-green: #00ca00;
  --color-light-green: #00ff00;

  /* common colors remains the same in light and dark modes */
  --color-common-black: #000;
  --color-common-dark-grey: #68686a;
  --color-common-grey: #bcc8d3;
  --color-common-light-grey: #d6dfe6;
  --color-common-xlight-grey: #eaecf0;
  --color-common-xxlight-grey: #f1f3f5;
  --color-common-xxxlight-grey: #f6f7f8;
  --color-common-white: #fff;

  --color-common-dark-ocra: #c59b26;
  --color-common-ocra: #e9bf50;
  --color-common-light-ocra: #f4e0ab;

  --color-common-dark-blue: #115c96;
  --color-common-blue: #207cc2;
  --color-common-light-blue: #68b1e9;
  --color-common-xlight-blue: #acd7f8;

  --color-common-red: #ca0000;
  --color-common-light-red: #ff0000;
  --color-common-yellow: #caca00;
  --color-common-light-yellow: #ffff00;
  --color-common-green: #00ca00;
  --color-common-light-green: #00ff00;

  /* common color usage */
  --color-darkcommon-xxlight-grey: #28445a;
  --color-darkcommon-xxxlight-grey: #101d29;
  --color-darkcommon-xxxlight-grey-80: #101d29cc;

  /* shadows */
  --box-shadow: 0 0.3rem 3rem 0 #00000030, 0 0.15rem 1.5rem 0 #00000030;
  --box-inset-shadow: inset 0 0.3rem 1.2rem 0 #00000030;
  --box-shadow-thin: 0 0.15rem 0.2rem 0 #00000030;
  --box-inset-shadow-thin: inset 0 0.15rem 0.2rem 0 #00000010;

  --box-shadow-blue: 0 0.3rem 3rem 0 #207cc224, 0 0.15rem 1.5rem 0 #207cc234;
  --box-inset-shadow-blue: inset 0 0.3rem 1.2rem 0 #207cc224;
  --box-shadow-thin-blue: 0 0.15rem 0.2rem 0 #11528320;
  --box-inset-shadow-thin-blue: inset 0 0.15rem 0.2rem 0 #11528320;

  --box-shadow-ocra: 0 0.3rem 3rem 0 #e9bf5024, 0 0.15rem 1.5rem 0 #e9bf5034;
  --box-inset-shadow-ocra: inset 0 0.3rem 1.2rem 0 #e9bf5024;
  --box-shadow-thin-ocra: 0 0.15rem 0.2rem 0 #e9bf5020;
  --box-inset-shadow-thin-ocra: inset 0 0.15rem 0.2rem 0 #e9bf5020;

  --common-box-shadow: 0 0.3rem 3rem 0 #00000030, 0 0.15rem 1.5rem 0 #00000030;
  --common-box-inset-shadow: inset 0 0.3rem 1.2rem 0 #00000030;
  --common-box-shadow-thin: 0 0.15rem 0.2rem 0 #00000030;
  --common-box-inset-shadow-thin: inset 0 0.15rem 0.2rem 0 #00000030;

  --common-box-shadow-blue: 0 0.3rem 3rem 0 #207cc224, 0 0.15rem 1.5rem 0 #207cc234;
  --common-box-inset-shadow-blue: inset 0 0.3rem 1.2rem 0 #207cc224;
  --common-box-shadow-thin-blue: 0 0.15rem 0.2rem 0 #11528320;
  --common-box-inset-shadow-thin-blue: inset 0 0.15rem 0.2rem 0 #11528320;

  /* styling */
  --border-radius-small: 0.3em;
  --border-radius: 0.8em;
  --border-radius-large: 2rem;
  --border-size: 0.04rem;
  --border-thick-size: 0.1rem;
  --border-xthick-size: 0.2rem;
  --border-xxthick-size: 0.3rem;
  --border: var(--border-size) solid;
  --border-thick: var(--border-thick-size) solid;
  --border-xthick: var(--border-xthick-size) solid;
  --border-xxthick: var(--border-xxthick-size) solid;
  --border-dashed: var(--border-size) dashed;
  --border-dashed-thick: var(--border-thick-size) dashed;
  --border-dashed-xthick: var(--border-xthick-size) dashed;
  --border-dashed-xxthick: var(--border-xxthick-size) dashed;
  --input-border: 0.3rem solid var(--color-xlight-grey);

  /* font size */
  --font-size-xxsmall: 0.4rem;
  --font-size-xsmall: 0.6rem;
  --font-size-small: 0.8rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.4rem;
  --font-size-xlarge: 1.8rem;
  --font-size-xxlarge: 2.2rem;
  --font-size-xxxlarge: 3rem;

  color: var(--color-blue);
  font-size: 1rem;
  overflow-x: hidden;
  overscroll-behavior: none;

  /* left navbar width */
  --left-navbar-width: 15rem;
}

/* dark mode */
body .theme-dark {
  --color-black: #fff;
  --color-dark-grey: #dddde2;
  --color-grey: #7599b6;
  --color-light-grey: #6186a5;
  --color-xlight-grey: #34546e;
  --color-xxlight-grey: #28445a;
  --color-xxxlight-grey: #101d29;
  --color-white: #03131f;

  --color-dark-blue: #d5edff;
  --color-blue: #bde2ff;
  --color-light-blue: #97cdf7;
  --color-xlight-blue: #508bb8;

  --color-xdark-ocra: #766023;
  --color-dark-ocra: #cca53a;
  --color-ocra: #e9bf50;
  --color-light-ocra: #f4e0ab;

  --color-black-40: #fff4;
  --color-dark-grey-40: #dddde240;
  --color-grey-40: #7599b640;
  --color-light-grey-40: #6186a540;
  --color-xlight-grey-40: #34546e40;
  --color-xxlight-grey-40: #28445a40;
  --color-xxxlight-grey-40: #101d2940;
  --color-white-40: #03131f40;

  --color-dark-blue-40: #d5edff40;
  --color-blue-20: #207cc233;
  --color-blue-40: #bde2ff40;
  --color-light-blue-40: #97cdf740;
  --color-xlight-blue-40: #508bb840;

  --color-dark-ocra-40: #cca53a40;
  --color-ocra-40: #e9bf5040;
  --color-light-ocra-40: #f4e0ab40;

  --color-black-80: #fffc;
  --color-dark-grey-80: #dddde2c0;
  --color-grey-80: #7599b6c0;
  --color-light-grey-80: #6186a5c0;
  --color-xlight-grey-80: #34546ec0;
  --color-xxlight-grey-80: #28445ac0;
  --color-xxxlight-grey-80: #101d29c0;
  --color-white-80: #03131fc0;

  --box-shadow: 0 0.3rem 3rem 0 #ffffff24, 0 0.15rem 1.5rem 0 #ffffff34;
  --box-inset-shadow: inset 0 0.3rem 1.2rem 0 #ffffff24;
  --box-shadow-thin: 0 0 0 0.04rem #ffffff24;
  --box-inset-shadow-thin: var(--box-shadow-thin);

  color: var(--color-blue);
  background: var(--color-white);
  width: 100vw;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 0;
}

blockquote {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: none;
}

table {
  border-style: none;
  border-collapse: separate;
  border-spacing: 0;
  border: 0;
  max-width: 100%;
}

table td {
  vertical-align: top;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
  line-height: 0;
}

img,
object,
iframe,
figure {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0.5em 0;
  padding: 0;
  line-height: 1;
}

h1 {
  font-size: var(--font-size-xlarge);
}

h2 {
  font-size: var(--font-size-large);
}

h3 {
  font-size: calc(var(--font-size-medium) + 0.3rem);
}

h4 {
  font-size: calc(var(--font-size-medium) + 0.2rem);
}

h5 {
  font-size: calc(var(--font-size-medium) + 0.1rem);
}

h6 {
  font-size: var(--font-size-medium);
}

.section-title {
  font-size: var(--font-size-xsmall);
  text-transform: uppercase;
  font-weight: normal;
  color: var(--color-black);
  letter-spacing: 0.2em;
  border-bottom: var(--border) var(--color-xlight-grey);
  padding-bottom: 0.8em;
}

p {
  margin: 0.8em 0;
}

small {
  font-size: 0.85em;
}

iframe {
  max-width: 100%;
}

.hidden {
  display: none;
}

hr {
  width: 100%;
  height: var(--border-size);
  background: var(--color-xlight-grey);
  margin: 1.5rem 0;
}

hr.no-line {
  background: transparent;
}

hr.small {
  margin: 0.5rem 0;
}

/* default links */
a {
  color: var(--color-blue);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: var(--color-light-blue);
}

/* default labels */
label {
  cursor: pointer;
}

/* default inputs */
input {
  font-family: 'Metropolis', sans-serif;
  border-radius: var(--border-radius-small);
  color: inherit;
  background: transparent;
  border: var(--border) var(--color-grey);
  font-size: 1em;
  margin: 0;
  padding: 0.4em 0.8em;
  min-width: 5em;
  cursor: text;
}
input:hover,
input:focus,
textarea:hover,
textarea:focus {
  border-color: var(--color-light-grey);
  box-shadow: inset 0 0 0 var(--border-thick-size) var(--color-light-grey);
}
input:disabled {
  border-color: var(--color-xlight-grey);
  background: var(--color-darkcommon-xxxlight-grey);
  color: var(--color-black);
  cursor: not-allowed;
  opacity: 0.7;
}
body input:autofill {
  color: var(--color-black) !important;
  background: transparent !important;
  border: var(--border) var(--color-grey) !important;
}
body.theme-dark input:autofill {
  border: var(--border) var(--color-xxlight-grey) !important;
}
body input:-webkit-autofill {
  color: var(--color-black) !important;
  background: transparent !important;
  border: var(--border) var(--color-grey) !important;
}
body.theme-dark input:-webkit-autofill {
  border: var(--border) var(--color-xxlight-grey) !important;
}

input[type='radio'] {
  appearance: none;
  display: inline-block;
  user-select: none;
  cursor: pointer;
  min-width: 0.8em;
  width: 0.8em;
  aspect-ratio: 1 / 1;
  border-radius: 1em;
  padding: 0.15em;
  vertical-align: middle;
}
input[type='radio']:is(:hover, :focus) {
  box-shadow: none;
  border: 0;
  outline: var(--border-thick) var(--color-grey) !important;
}
input[type='radio']:checked {
  outline: var(--border-thick) var(--color-grey) !important;
  border: 0;
  background: var(--color-grey);
  background-clip: content-box;
}

::placeholder {
  color: var(--color-grey);
  opacity: 1;
}
.theme-dark ::placeholder {
  color: var(--color-black-40);
}

/* default checkboxes */
input[type='checkbox'] {
  font-size: 1em;
  padding: 0;
  margin: 0;
  width: 1.2em;
  height: 1.2em;
  min-width: 0;
  vertical-align: middle;
  border: var(--border) var(--color-xlight-grey);
  border-radius: var(--border-radius-small);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  color: var(--color-black);
  cursor: pointer;
}
input[type='checkbox']:hover {
  border-color: var(--color-light-grey);
}
input[type='checkbox']:checked:after {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 24" width="100%" fill="white"><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>'); /* icon-done */
  position: absolute;
  top: 0.1em;
  left: 0.1em;
  width: 1em;
  height: 1em;
  color: var(--color-black);
  mix-blend-mode: difference;
}

/* input numeric */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* default textarea */
textarea {
  min-height: 5em;
  font-family: 'Metropolis', sans-serif;
  border-radius: var(--border-radius-small);
  color: var(--color-black);
  background: transparent;
  font-size: 1em;
  margin: 0;
  padding: 0.4em 0.635em;
  min-width: 5em;
  resize: vertical;
  border: var(--border) var(--color-grey);
  overflow-y: scroll;
  resize: none;
}

/* main App container */
.App {
  display: flex;
  flex-direction: column;
  max-height: 100svh;
  min-height: 100svh;
}
.App > div:not(.notifications-panel) {
  flex-grow: 2;
  transform: translate(0);
  overflow: hidden;
  display: flex;
}
.App > div:not(.notifications-panel):has(> .multiselect-options) {
  -webkit-transform: none;
  transform: none;
}
.App > div.theme-dark.root-wrapper {
  display: grid;
  grid-template-areas: 'main-page debug-panel';
  grid-template-columns: 1fr min-content;
  grid-template-rows: auto;
  justify-content: stretch;
}

/* loading screen */
.App > .loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0rem;
  background: transparent;
  z-index: 1000;
}
.App > .loading.visible {
  height: 0.3rem;
}
.App > .loading.visible:after {
  content: '';
  display: block;
  position: relative;
  width: 0;
  left: 0;
  height: 100%;
  animation: loading 3s ease-in-out infinite;
  background: var(--color-common-blue);
}
@keyframes loading {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0%;
    left: 100%;
  }
}

/* background of modal windows */
.modal-background {
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--color-black);
  opacity: 0.5;
}
.theme-dark .modal-background {
  background: var(--color-white);
  opacity: 0.8;
}
.modal-background > .icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
}

/* error screen */
#root > .error {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-red);
}

/* multiscreen permissions */
.multiscreen-popup {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.flex {
  display: flex;
  width: 100%;
}

/* flex alignments */
.vertical-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

/* text alignments */
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.error-message {
  font-style: normal;
  font-weight: 700;
  color: var(--color-xlight-red);
}

.success-message {
  font-style: normal;
  font-weight: 700;
  color: var(--color-blue);
}

.input-error {
  border-color: var(--color-xlight-red) !important;
  border-width: var(--border-thick-size) !important;
}

select {
  font-family: 'Metropolis', sans-serif;
  border-radius: var(--border-radius-small);
  color: var(--color-black);
  background: transparent;
  border: var(--border) var(--color-grey);
  font-size: 0.7em;
  margin: 0;
  padding: 0.7em 1.2em;
  min-width: 5em;
}
.theme-dark select {
  border: var(--border) var(--color-xxlight-grey);
}

/* grid */
.row {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.row.vertical-top {
  align-items: flex-start;
}
.row.vertical-center {
  align-items: center;
}
.row.vertical-bottom {
  align-items: flex-end;
}
.row > *.vertical-top {
  align-self: flex-start;
}
.row > *.vertical-center {
  align-self: center;
}
.row > *.vertical-bottom {
  align-self: flex-end;
}
.row > * {
  position: relative;
  padding: 0.4rem;
}
.row > .w1 {
  width: calc(8.3333%);
}
.row > .w2 {
  width: calc(16.6666%);
}
.row > .w3 {
  width: calc(25%);
}
.row > .w4 {
  width: calc(33.3333%);
}
.row > .w5 {
  width: calc(41.6665%);
}
.row > .w6 {
  width: calc(50%);
}
.row > .w7 {
  width: calc(58.3335%);
}
.row > .w8 {
  width: calc(66.6667%);
}
.row > .w9 {
  width: calc(75%);
}
.row > .w10 {
  width: calc(83.3334%);
}
.row > .w11 {
  width: calc(91.6667%);
}
.row > .w12 {
  width: calc(100%);
}
.row > .nomargin {
  padding: 0;
}
.row > .o1 {
  margin-left: 8.3333%;
}
.row > .o2 {
  margin-left: 16.6666%;
}
.row > .o3 {
  margin-left: 25%;
}
.row > .o4 {
  margin-left: 33.3333%;
}
.row > .o5 {
  margin-left: 41.6665%;
}
.row > .o6 {
  margin-left: 50%;
}
.row > .o7 {
  margin-left: 58.3335%;
}
.row > .o8 {
  margin-left: 66.6667%;
}
.row > .o9 {
  margin-left: 75%;
}
.row > .o10 {
  margin-left: 83.3334%;
}
.row > .o11 {
  margin-left: 91.6667%;
}

.sonio-header-logo {
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--font-size-large);
}
.sonio-header-logo > img {
  width: 7.5rem;
}

.clickable {
  cursor: pointer;
}

.italic {
  font-style: italic;
}

.mr-small {
  margin-right: 1em;
}

.ml-small {
  margin-left: 1em;
}

.mb-small {
  margin-bottom: 1em;
}

.mt-small {
  margin-top: 1em;
}

.text-xxsmall {
  font-size: var(--font-size-xxsmall);
  line-height: 1.4;
}

.text-xsmall {
  font-size: var(--font-size-xsmall);
  line-height: 1.4;
}

.text-small {
  font-size: var(--font-size-small);
  line-height: 1.4;
}

.text-medium {
  font-size: var(--font-size-medium);
  line-height: 1.4;
}

.text-large {
  font-size: var(--font-size-large);
  line-height: 1.4;
}

.text-xlarge {
  font-size: var(--font-size-xlarge);
  line-height: 1.4;
}

.text-xxlarge {
  font-size: var(--font-size-xxlarge);
  line-height: 1.4;
}

.text-xxxlarge {
  font-size: var(--font-size-xxxlarge);
  line-height: 1.4;
}
