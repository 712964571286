.configuration-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  min-height: 100%;
  height: 100%;
  overflow: auto;
}

.configuration-container .title {
  display: flex; /* Enables Flexbox */
  flex-direction: column; /* Keep items in a column */
  padding-top: 0;
  padding-bottom: 1rem;
}

.configuration-container .popup-message {
  font-size: var(--font-size-xlarge);
  margin-bottom: 0.3rem;
}

.configuration-container .popup-hint {
  font-size: var(--font-size-medium);
  margin-bottom: 0.3rem;
}

.configuration-container .cta {
  justify-content: center;
  width: 100%;
  display: flex;
  gap: 0.5rem;
}

.filter-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  width: 100%;
  max-width: 40.5rem;
  padding: 0 !important;
  align-items: start;
}

.share-document-label {
  font-weight: 700;
  margin: 1.5rem 0;
  color: var(--color-dark-blue);
}

.attachment-item .checkbox-input-wrapper.checked {
  border: 0;
}

.attachment-item {
  gap: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-xxlight-grey);
  padding: 0 2px 0.6rem 2px;
  color: var(--color-dark-blue);
}

.attachment-item .icon {
  width: auto;
  height: 100%;
  display: flex;
  padding: 1px;
  align-items: flex-start;
}

.attachment-item .icon svg {
  width: auto;
  height: 1rem;
}

.attachment-title {
  line-height: 140%;
  font-size: 1rem;
  flex-grow: 1;
}

.download-icon svg,
.delete-icon svg {
  cursor: pointer;
  opacity: 0.5;
}

.download-icon svg:hover,
.delete-icon svg:hover {
  opacity: 1;
}

.upload-document-button {
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin: 1.5rem 0;
}

.filter-list-container .checkbox {
  width: 100%;
  justify-content: space-between;
}

.configuration-container small.sub-title {
  display: block;
  width: 100%;
}

.configuration-container h3 + small.sub-title {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.configuration-container h2 + small.sub-title {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
}

.configuration-container h1 + hr {
  margin-top: -0.5rem;
}

.configuration-container h1 + hr + small.sub-title {
  margin-top: -1rem;
  margin-bottom: 1.5rem;
}

.configuration-container .row.spacer {
  margin-bottom: 1.5rem;
}

.configuration-container hr.section-end {
  margin-bottom: 0.5rem;
}
