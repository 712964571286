.country-container {
  height: var(--font-size-xxxlarge);
  display: flex;
  gap: var(--font-size-xsmall);
  padding-left: var(--font-size-xsmall);
  padding-right: var(--font-size-xsmall);
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border: var(--border);
  border-radius: var(--border-radius-small);
  background: var(--color-darkcommon-xxxlight-grey);
  border-color: var(--color-xlight-grey);
  color: var(--color-black);
  cursor: not-allowed;
  opacity: 0.7;
}

.country-flag-wrapper {
  height: var(--font-size-large);
  width: var(--font-size-large);
}
