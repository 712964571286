.site-information-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
  overflow: auto;
}

.site-information-container .section {
  display: flex;
  flex-direction: column;
}
.site-information-container .section.message {
  margin-top: 30px;
}
.site-information-container .section .title {
  font-size: 1rem;
  color: var(--color-blue);
}
.site-information-container .section .subtitle {
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
  margin-bottom: 10px;
  margin-top: 5px;
  color: var(--color-light-grey);
}
.site-information-container .content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.site-information-container .image-thumbnail {
  width: 200px;
  max-height: 200px;
  margin-right: 30px;
}

.site-information-container .image-actions {
  display: flex;
  flex-direction: column;
}

.site-information-container .image-actions > .button {
  margin-bottom: 20px;
}

.site-information-container .button {
  font-family: 'Metropolis', sans-serif;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  background: var(--color-blue);
  color: var(--color-white);
  font-size: 1em;
  line-height: 1em;
  padding: 0.8em 1.2em;
  min-width: 3em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.site-information-container .ql-editor {
  font-size: 0.7rem;
}
.site-information-container .ql-toolbar,
.site-information-container .ql-container {
  border: 1px solid var(--color-blue) !important;
}
.site-information-container .ql-toolbar {
  border-bottom: none !important;
}
.site-information-container button.ql-active > svg > line,
.site-information-container button.ql-active > svg > path {
  stroke: var(--color-blue) !important;
}
