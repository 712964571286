.examination-live-fetus-panel-container {
  padding: 2rem;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'sex position' 'placenta position';
  grid-gap: 2rem;
}
.examination-live-fetus-panel-container.hidden-fetus-sex {
  grid-template-areas: 'placenta position' 'placenta position';
}
.examination-live-fetus-panel-container.hidden-fetus-position {
  grid-template-areas: 'sex' 'placenta';
}
.examination-live-fetus-panel-container.hidden-fetus-position.hidden-fetus-sex {
  grid-template-areas: 'placenta' 'placenta';
}

.examination-live-fetus-panel-container > div {
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-small);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.examination-live-fetus-panel-container > div > h2 {
  margin: 0;
}

.examination-sidebar-fetus_sex {
  grid-area: sex;
}
.examination-sidebar-fetus_placenta {
  grid-area: placenta;
}
.examination-sidebar-fetus_position {
  grid-area: position;
}

.examination-live-fetus-panel-container .examination-sidebar-fetus {
  text-align: center;
  margin: 0;
  transform: scaleX(1);
  transition: transform 0.3s ease-in-out 0.3s;
  width: 100%;
}
.examination-live-fetus-panel-container .examination-sidebar-fetus svg {
  width: 12rem;
  height: auto;
  display: block;
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
  margin: 0 auto;
}
.examination-live-fetus-panel-container .examination-sidebar-fetus.flip {
  transform: scaleX(-1);
}
.examination-live-fetus-panel-container .examination-sidebar-fetus.rotate-0 svg {
  transform: rotate(0deg);
}
.examination-live-fetus-panel-container .examination-sidebar-fetus.rotate-90 svg {
  transform: rotate(90deg);
}
.examination-live-fetus-panel-container .examination-sidebar-fetus.rotate-180 svg {
  transform: rotate(180deg);
}
.examination-live-fetus-panel-container .examination-sidebar-fetus.rotate-270 svg {
  transform: rotate(270deg);
}
.examination-live-fetus-panel-container .examination-sidebar-fetus svg .fetus-path {
  fill: var(--color-xxxlight-grey);
}
.examination-live-fetus-panel-container .examination-sidebar-fetus svg circle.fetus-path {
  fill: var(--color-grey);
}
.theme-dark .examination-live-fetus-panel-container .examination-sidebar-fetus svg circle.fetus-path {
  fill: var(--color-xxlight-grey);
}

.examination-live-fetus-panel-container .select-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.examination-live-fetus-panel-container .examination-sidebar-fetus_heading {
  display: flex;
  gap: 1em;
  align-items: center;
  z-index: 100;
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection {
  position: relative;
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection .detected-by {
  display: flex;
  gap: 0.1em;
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection .detected-by > span {
  color: var(--color-white);
  background-color: var(--color-light-grey);
  border-radius: var(--border-radius);
  padding: 0.05em 0.3em;
  cursor: pointer;
  margin-right: 0.2em;
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection .detected-by > span.unusual {
  background: var(--color-ocra);
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_contradicted {
  color: var(--color-ocra);
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection .dropdown {
  position: absolute;
  top: calc(100% + 0.4rem);
  left: 50%;
  transform: translateX(-50%);
  background: var(--color-white);
  color: var(--color-blue);
  padding: 1.5rem 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  min-width: 20rem;
  max-width: 25rem;
  font-size: var(--font-size-small);
  line-height: var(--font-size-medium);
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection .dropdown::before {
  content: '';
  position: absolute;
  top: -0.5rem;
  left: calc(50% - 0.3rem);
  border: 0.6rem solid transparent;
  border-top: 0;
  border-bottom-color: var(--color-white);
}
.examination-live-fetus-panel-container .examination-live-fetus-panel_sex_detection .dropdown > div {
  margin-bottom: 0.8em;
}
