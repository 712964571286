.patient-application-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
  overflow: auto;
}

.config-error {
  font-weight: bold;
  color: var(--color-ocra);
}

.inline-link {
  color: var(--color-ocra);
  text-decoration: underline;
}
