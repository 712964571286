.login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: var(--font-size-medium);
}

.login-page .login-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.login-options {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}
.login-sso {
  padding-left: 2rem;
  width: 33%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  border-left: var(--border) var(--color-xxlight-grey);
}
.login-sso-backend {
  display: inline-flex;
  align-items: center;
  background: var(--color-common-white);
  padding: 0.2rem 0.6rem 0.2rem 0.2rem;
  gap: 0.4rem;
  border-radius: var(--border-radius-small);
}
.login-sso-backend > img {
  width: 4rem;
}
.login-sso-backend > span {
  font-size: var(--font-size-small);
  color: var(--color-common-black);
}

.login-page .login-container .logo-container {
  text-align: center;
  font-size: var(--font-size-xlarge);
  animation: login-page-fadein 0.5s ease-in-out forwards;
}

.login-page .login-form {
  opacity: 0;
  animation: login-page-fadein 0.5s ease-in-out 0.5s forwards;
}

.login-page .login-form-item {
  display: block;
  position: relative;
  margin: 2rem 0 1rem 0;
  font-size: var(--font-size-large);
  display: flex;
  align-items: center;
  border: var(--border) var(--color-grey);
  border-radius: var(--border-radius-small);
}
.theme-dark .login-page .login-form-item {
  border: var(--border) var(--color-xxlight-grey);
}

.login-page .login-form-item > span:first-child {
  min-width: 9em;
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  padding: 0 1rem;
}
.login-page .login-form-item > span:last-child {
  flex-grow: 2;
}
.login-page .login-form-item span:last-child input {
  border: 0 !important;
}
.login-page .login-form-item input {
  margin: 0;
  width: 100%;
}

.login-page .login-form-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
}
.login-page .login-form-submit label {
  font-size: var(--font-size-medium);
}

.login-page .login-form-forgot-pwd {
  font-size: 0.5em;
  display: flex;
  justify-content: space-evenly;
  padding-top: 0.5rem;
}

.login-page .error {
  color: var(--color-red);
}

@keyframes login-page-fadein {
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-page .login-page-set-language {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: var(--font-size-small);
}
