.edit-checklist-wrapper .tabs-container {
  font-size: var(--font-size-small);
}
.edit-checklist-wrapper .exam-report-dialog-header_title {
  justify-content: space-between;
  align-items: center;
}
.edit-checklist-wrapper .exam-report-dialog-header_title .edit-checklist_batch-actions {
  font-size: var(--font-size-small);
}

.edit-checklist-wrapper .edit-checklist_group_item.header {
  position: sticky;
  top: 0;
  font-size: var(--font-size-small);
  background: var(--color-xxlight-grey) !important;
  box-shadow: 1rem 0 0 0 var(--color-xxlight-grey);
  padding: 0.2rem 0 0.2rem 0.6rem;
  translate: -1rem -0.5rem;
  width: calc(100% + 1rem);
  z-index: 100;
  align-items: center;
}
.edit-checklist-wrapper .edit-checklist_group_item.header > div:last-child {
  min-width: calc(0.8rem + 1.8em);
}
.edit-checklist-wrapper .edit-checklist_group_item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-gap: 0.5rem;
  border-bottom: var(--border) var(--color-xlight-grey);
  align-items: center;
}
.edit-checklist-wrapper .edit-checklist_group_item:hover {
  background: var(--color-xlight-grey-40);
}
.edit-checklist-wrapper .edit-checklist_group_item > div {
  font-size: var(--font-size-small);
}
.edit-checklist-wrapper .edit-checklist_group_item .edit-checklist_group_item_name {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.edit-checklist-wrapper .edit-checklist_group_item .edit-checklist_group_item_name > span {
  min-width: 10rem;
}
.edit-checklist-wrapper .edit-checklist_group_item[data-status='unusual'] .edit-checklist_group_item_name {
  color: var(--color-ocra);
}
.edit-checklist-wrapper .edit-checklist_group_item > div {
  padding: 0.2rem 0.4rem;
}
.edit-checklist-wrapper .edit-checklist_group_item > :is(.edit-checklist_group_item_button, .quick_actions) {
  font-size: var(--font-size-xsmall);
  line-height: 1.9;
}
.edit-checklist-wrapper .edit-checklist_batch-actions {
  font-size: var(--font-size-xsmall);
}

.edit-checklist-wrapper .exam-report-dialog-comment {
  display: block !important;
  background-color: var(--color-xxxlight-grey);
}
.edit-checklist-wrapper .exam-report-dialog-comment .section-title {
  cursor: pointer;
}
.edit-checklist-wrapper .exam-report-dialog-comment .section-title:last-child {
  border-bottom: 0;
  margin: 0;
  padding: 0;
}

.edit-checklist-wrapper .edit-checklist_note {
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}
.edit-checklist-wrapper .edit-checklist_note > :first-child {
  flex-basis: 15rem;
}
.edit-checklist-wrapper .no-items {
  text-align: center;
  font-size: var(--font-size-small);
  padding: 2rem;
  color: var(--color-grey);
}
.edit-checklist-wrapper .no-items > a {
  text-decoration: underline;
  cursor: pointer;
  display: block;
}
