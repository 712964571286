.text-input {
  display: inline-block;
  position: relative;
}

.text-input > label {
  text-align: center;
  display: block;
  margin-bottom: 0.3em;
}

.text-input .text-input-field {
  position: relative;
  display: block;
}

.text-input-field.has-maxLength {
  border: var(--border) var(--color-grey);
  border-radius: var(--border-radius-small);
  display: flex !important;
}

.text-input-field.has-maxLength input {
  border: none;
}

.text-input.has-icon input {
  padding-left: 2.2em;
}
.text-input.has-icon .icon {
  position: absolute;
  top: 0.6em;
  left: 0.6em;
  cursor: pointer;
}
.text-input.has-icon .text-input_loader {
  position: absolute;
  top: 0.2em;
  left: 0.6em;
}
.text-input.has-icon .icon.close {
  left: auto;
  right: 0.6em;
}
.text-input.variant-underline input {
  border: 0;
  border-bottom: var(--border-dashed) var(--color-xlight-grey);
  border-radius: 0;
}
.text-input.variant-underline input:hover,
.text-input.variant-underline input:focus {
  box-shadow: none;
}

.text-input.full-width {
  display: block;
  width: 100%;
}
.text-input.full-width input {
  width: 100%;
}
.text-input.full-width .text-input-field {
  display: block;
  width: 100%;
}

.text-input-field .text-input-length {
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-right: 0.8em;
  font-size: var(--font-size-small);
  font-weight: 400;
  line-height: 1rem;
}
