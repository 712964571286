.exam-confirm-matching-banner-dt-container {
  padding: 0.5rem 1rem 0rem 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: var(--font-size-small);
  gap: 1rem;
}
.exam-confirm-matching-banner-dt {
  background: var(--color-xxxlight-grey);
  border-radius: var(--border-radius);
  padding: 0.5rem 1rem;
  flex-grow: 2;
  display: flex;
  align-items: center;
  gap: 2rem;
}
.exam-confirm-matching-banner-dt h2 {
  margin: 0;
  font-size: var(--font-size-medium);
}
.exam-confirm-matching-banner-dt-stats {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.exam-confirm-matching-banner-dt-stats .exam-confirm-matching-banner-dt-stat {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-small);
}
.exam-confirm-matching-banner-dt-stats .exam-confirm-matching-banner-dt-stat b {
  font-size: var(--font-size-small);
}
.exam-confirm-matching-banner-dt-stats .exam-confirm-matching-banner-dt-stat.ko b {
  color: var(--color-ocra);
}
.exam-confirm-matching-banner-dt-stats div.exam-confirm-matching-banner-dt-stat-graph {
  min-width: 2rem;
  max-width: 2rem;
}
.exam-confirm-matching-banner-dt-text {
  font-size: var(--font-size-small);
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  padding: 0.3em 1em;
}
.exam-confirm-matching-banner-dt-container .exam-confirm-matching-banner-dt-new-exam {
  position: relative;
  max-width: 50%;
  margin-bottom: -3.5em;
}
.exam-confirm-matching-banner-dt-container .exam-confirm-matching-banner-dt-new-exam > div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius);
  padding: 0.5rem;
  gap: 0.3rem;
}
.exam-confirm-matching-banner-dt-container .exam-confirm-matching-banner-dt-new-exam label {
  line-height: 1;
}
