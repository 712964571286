.examination-review-sharing-preview-popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
}

.examination-review-sharing-preview-popup {
  position: relative;
  z-index: 10000;
  max-width: 46rem;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 2rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.examination-review-sharing-preview-popup .share-popup-close {
  width: 100%;
  text-align: right;
  margin-bottom: 1rem;
}

.examination-review-sharing-preview-popup .share-popup-close .icon.close {
  height: min-content;
  width: min-content;
}

.examination-review-sharing-preview-popup .share-popup-close svg {
  font-size: var(--font-size-xlarge);
  cursor: pointer;
}

.examination-review-sharing-preview-popup .popup-qr-code-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.examination-review-sharing-preview-popup .popup-qr-code-outer-container {
  display: flex;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  gap: 1.5rem;
}

.sharing-popup-title {
  color: var(--color-black);
  font-weight: bold;
  line-height: 100%;
  font-size: var(--font-size-large);
}

.sharing-popup-title-number {
  cursor: pointer;
}

.sharing-popup-title-number:hover {
  color: var(--color-light-blue);
  border-bottom: var(--border-thick) var(--color-light-blue);
  text-decoration: none;
}

.sharing-popup-hr {
  background-color: var(--color-xlight-grey);
}

.examination-review-sharing-preview-popup .popup-qr-code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.examination-review-sharing-preview-popup .popup-qr-code {
  background: var(--color-black);
  border-radius: var(--border-radius);
  padding: var(--font-size-xsmall);
  display: flex;
  align-items: center;
}

.examination-review-sharing-preview-popup .sharing-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin-top: 1.5rem;
  width: 60%;
}

.examination-review-sharing-preview-popup .sharing-options label {
  text-align: left;
  font-size: var(--font-size-small);
  display: flex;
  margin-top: 0.5rem;
}

.success-sharing-label div span:first-child {
  display: block;
}

.success-sharing-label div span:first-child::after {
  content: '.';
}

.examination-review-sharing-preview-popup .sharing-options .done-icon {
  margin-top: 0.25rem;
  transform: scale(1.5);
  margin-right: 0.5rem;
}

.examination-review-sharing-preview-popup .sharing-options .sharing-code {
  font-size: var(--font-size-xlarge);
  line-height: var(--font-size-xlarge);
  display: flex;
  justify-content: center;
  margin: 0.2rem 0 1rem 0;
}

.examination-review-sharing-preview-popup .sharing-options .success-sharing-label b {
  text-align: center;
  letter-spacing: 0.2rem;
}

.sharing-code-instructions {
  font-size: var(--font-size-small);
}

.sharing-popup-media-files {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 2rem 0;
}

.sharing-popup-media-files-item {
  padding: 0 1rem;
  font-weight: 400;
  font-size: var(--font-size-small);
}

.sharing-options-label {
  font-size: var(--font-size-small);
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 400;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  letter-spacing: 0.15rem;
  color: var(--color-common-white);
}

.sharing-popup-media-files-item .count {
  padding-left: 0.25rem;
  font-size: calc(var(--font-size-small) + 1px);
}

.vertical-line {
  width: 1px;
  height: 17px;
  background-color: #5b7b94;
}

.flex-line-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.share-patient-email {
  padding: 0.6em 0.8em;
}
