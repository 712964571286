.examination-medical-history-container {
  display: flex;
}

/* chapters */
.examination-medical-history-chapters {
  padding: 0;
  font-size: var(--font-size-medium);
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  overflow: auto;
  flex-grow: 10;
  margin-left: 4rem;
}
.examination-medical-history-chapters .row > * {
  padding: 0.4rem;
}

.examination-medical-history-chapters > .chapter {
  position: relative;
  padding: 0rem 0rem 3rem;
  min-height: 100vh;
  /*scroll-snap-align: start;*/
  margin-bottom: 1rem;
}
.examination-medical-history-chapters > .chapter > h2 {
  text-align: center;
  margin: 0;
  padding: 1.2rem;
  font-size: 1.2em;
  position: relative;
  top: 0;
  z-index: 1;
  border-bottom: var(--border) var(--color-xlight-grey);
}
.examination-medical-history-chapters > .chapter > h3 {
  font-size: 0.6rem;
  text-align: center;
  margin: 0;
}
.examination-medical-history-chapters .chapter > .row {
  padding: 1rem;
}
.examination-medical-history-chapters .patient-data {
  justify-content: center;
  border-bottom: var(--border) var(--color-xlight-grey);
}
.examination-medical-history-chapters img.crat {
  height: 1em;
  width: auto;
  display: inline-block;
  vertical-align: center;
  margin-left: 0.5em;
}

.examination-medical-history-cta-bar {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  background-color: var(--color-xxxlight-grey);
  padding: 0.4rem;
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-medium);
  box-shadow: var(--box-shadow-thin);
  display: flex;
  gap: 1rem;
}

.examination-medical-history-patient-container {
  position: fixed;
  top: 0.9rem;
  right: 0.9rem;
  z-index: 100;
}

.examination-medical-history-warning {
  text-align: center;
  flex-grow: 2;
  font-size: var(--font-size-small);
  color: var(--color-grey);
}
