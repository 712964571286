.numeric-input {
  white-space: nowrap;
  display: inline-block;
  align-items: center;
}
.numeric-input.hidden {
  display: none;
}
.numeric-input > label {
  text-align: center;
  display: block;
  margin-bottom: 0.3em;
}
.numeric-input > div {
  display: inline-block;
  vertical-align: middle;
}
.numeric-input input {
  width: 3em !important;
  text-align: center;
  margin: 0 0.2em;
  padding-left: 0;
  padding-right: 0;
}

.numeric-input .add-wrapper,
.numeric-input .subtract-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey);
  border: var(--border-thick);
  border-radius: var(--border-radius-large);
  height: 1.4em;
  width: 1.4em;
  line-height: 1.3;
  cursor: pointer;
}

.numeric-input .add-wrapper:hover,
.numeric-input .subtract-wrapper:hover {
  color: var(--color-light-blue);
}

.numeric-input-textual-value {
  display: flex;
  align-items: center;
}
.numeric-input-textual-value > .value {
  background: var(--color-xlight-grey);
  border-radius: var(--border-radius);
  font-size: var(--font-size-medium);
  flex-grow: 2;
}
.numeric-input-textual-value > .icon {
  display: inline-block;
  color: var(--color-grey);
  border: var(--border-thick);
  border-radius: var(--border-radius-large);
  height: 1.4em;
  width: 1.4em;
  line-height: 1.3;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  margin-left: 0.1rem;
}
.numeric-input-textual-value > .icon:hover {
  color: var(--color-light-blue);
}
