.report-sidebar-wrapper {
  --report-sidebar-width: 16rem;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  width: var(--report-sidebar-width);
  position: sticky;
  align-self: flex-start;
  height: 100%;
  border-left: var(--border) var(--color-xxlight-grey);
  transition: width 0.3s ease;
  z-index: 1;
  position: relative;
}
.report-sidebar-inner {
  width: var(--report-sidebar-width);
  overflow: auto;
  height: 100%;
}

.report-sidebar-wrapper.closed {
  width: 0;
}

.report-sidebar-wrapper.hide {
  border-left: none;
  overflow: visible;
}

.report-sidebar-open-button {
  width: 2.2rem;
  height: 2.2rem;
  padding: 0;
  color: var(--color-blue);
  background-color: var(--color-xxlight-grey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-large);
  cursor: pointer;
  position: absolute;
  right: 100%;
  top: 5.5rem;
  z-index: 2;
}
.report-sidebar-open-button:hover {
  color: var(--color-black);
}

.report-sidebar-section {
  background-color: var(--color-xxlight-grey);
  border-bottom: var(--border-thick) var(--color-white);
  padding: 0.6rem 0.8rem;
  font-size: var(--font-size-small);
  font-weight: 400;
}

.report-sidebar-section.hide {
  visibility: hidden;
}

.report-sidebar-section-title {
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  cursor: pointer;
}

.report-sidebar-section-title > h2 {
  border-bottom: none;
  padding-bottom: 0;
}

.report-sidebar-section-content {
  margin-top: 0.5rem;
}

.report-sidebar-section-title .arrow-icon {
  margin-left: auto;
}

.report-sidebar-wrapper .patient-attribute {
  font-weight: 700;
  min-width: 4rem;
  display: inline-block;
}
