/* used in SharingSelect.js as well */

.examination-live-grid-container {
  flex-grow: 2;
  overflow: auto;
  position: relative;
  z-index: 10;
  padding: 1rem 1.6rem;
}

.examination-live-grid.videos {
  min-height: 90vh;
}

.examination-live-grid + .examination-live-grid {
  padding-top: 2rem;
}

.slide-grid-container {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(var(--number-of-columns), 1fr);
  gap: 1.4rem;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
  --number-of-columns: 3;
}

.slide-grid-heading > h2 {
  margin-top: 0;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding-bottom: 0.6rem;
  text-align: center;
  font-size: var(--font-size-medium);
}

@container page-wrapper (min-width: 1600px) {
  .slide-grid-container {
    grid-template-columns: repeat(calc(var(--number-of-columns) * 1.2), 1fr);
  }
}

@container page-wrapper (min-width: 1800px) {
  .slide-grid-container {
    grid-template-columns: repeat(calc(var(--number-of-columns) * 1.4), 1fr);
  }
}

@container page-wrapper (min-width: 2000px) {
  .slide-grid-container {
    grid-template-columns: repeat(calc(var(--number-of-columns) * 1.7), 1fr);
  }
}

@container page-wrapper (max-width: 1000px) {
  .slide-grid-container {
    grid-template-columns: repeat(max(1, calc(var(--number-of-columns) / 2)), 1fr);
  }
}

@container page-wrapper (max-width: 700px) {
  .slide-grid-container {
    grid-template-columns: repeat(max(1, calc(var(--number-of-columns) / 3)), 1fr);
  }
}

.examination-live-grid-container .examination-live-slide[data-is-zoomed='true'] {
  transform: scale(1.05);
  box-shadow: var(--box-shadow);
  background: var(--color-blue) !important;
}

.examination-live-grid-container .examination-live-slide {
  position: relative;
  display: flex;
  aspect-ratio: 4 / 3;
  border-radius: var(--border-radius-small);
  background: var(--color-darkcommon-xxxlight-grey);
  transition: background-color 0.3s ease-in-out;
  border: 0.3rem solid transparent;
  cursor: pointer;
  animation: examination-live-slide-fadein 0.6s ease-in-out forwards;
}

@keyframes examination-live-slide-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.examination-live-slide.next-matching {
  border: 0.3rem dashed var(--color-common-blue);
}

.examination-live-slide.has-media.not-verified {
  border: 0.3rem dashed var(--color-common-white);
}

.examination-live-slide.unusual .slide-label {
  color: var(--color-ocra);
}

.examination-live-slide.unusual::after {
  content: '';
  position: absolute;
  top: 0.5rem;
  height: calc(100% - 1rem);
  border-left: var(--border-xxthick) var(--color-ocra);
  left: calc(var(--border-xxthick-size) * -1);
  z-index: 1000;
}

.examination-live-slide.preclicked {
  background: var(--color-xxlight-grey);
}

.examination-live-slide.at-risk {
  background: var(--color-xxlight-grey-80);
}

.examination-live-slide.at-risk.preclicked {
  background: var(--color-xlight-grey);
}

.examination-empty-grid {
  height: calc(100% - 5rem);
  align-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  padding: 0.5rem;
  font-size: var(--font-size-xlarge);
  line-height: var(--font-size-xxlarge);
  color: var(--color-xlight-grey);
}

.examination-empty-grid img {
  width: 100%;
  height: auto;
}

/* drag and drop */
.is-dragging .examination-live-slide > * {
  pointer-events: none;
}

.examination-live-slide.dragging .slide-live-media,
.examination-live-slide.dragging .slide-live-container-print,
.examination-live-slide.dragging .slide-header::after {
  opacity: 0 !important;
}

.examination-live-slide.drag-overing {
  background: var(--color-blue);
}

/* quick matching */
.examination-live-grid-unverified {
  background: var(--color-xxxlight-grey);
  padding: 2rem;
  margin-bottom: 1rem;
}

.examination-live-grid-unverified h2 {
  margin-bottom: 0;
}

.manual-upload-slide {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 0;
  color: var(--color-common-grey);
}

.manual-upload-slide-icon {
  font-size: var(--font-size-xxlarge);
}

.manual-upload-slide-description {
  text-align: center;
  font-size: var(--font-size-small);
}
