.edit-flash-report-name-title {
  font-size: var(--font-size-medium);
  font-weight: 700;
  line-height: var(--font-size-medium);
  color: white;
  margin-bottom: 2rem;
}

.edit-flash-report-name-buttons {
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  gap: 1rem;
}
