.arrange-on-top_placeholder {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.arrange-on-top_wrapper {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100svh;
  max-height: none;
  transform: translate(0, 0);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius-small);
}

.arrange-on-top_placeholder.loading {
  visibility: hidden;
}

.arrange-on-top_wrapper.loading {
  visibility: hidden;
}

.arrange-on-top_wrapper .arrange-on-top_inner {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-width: auto;
  scrollbar-color: var(--color-xlight-grey) var(--color-white);
}

.arrange-on-top_wrapper.variant-balloon::before {
  content: '';
  position: absolute;
  top: -1rem;
  left: calc(var(--balloon-arrow-left, 50%) - 0.3rem);
  border: 0.6rem solid transparent;
  border-bottom-color: var(--color-white);
  z-index: -1;
}
.arrange-on-top_wrapper.variant-balloon.position-top::before {
  top: auto;
  bottom: -1rem;
  rotate: 180deg;
}
