.slide-panel-dt-container .slide-panel-dt-main_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 2rem;
  container-type: size;
  container-name: slide-panel-dt-main_wrapper;
}
.slide-panel-dt-container .slide-panel-dt-main_inner {
  display: flex;
  gap: 1rem;
  flex-grow: 2;
  flex-direction: row;
  height: 100%;
}

@container slide-panel-dt-main_wrapper (max-aspect-ratio: 4/3) {
  .slide-panel-dt-alternatives,
  .slide-panel-dt-sidebar-container {
    max-height: 100%;
    overflow: auto;
    flex-grow: 2;
  }
  .slide-panel-dt-container .slide-panel-dt-main_inner {
    flex-direction: column;
  }
  .slide-panel-dt-container .slide-panel-dt-main {
    flex-grow: 1;
    min-height: 50%;
    max-height: 50%;
  }
  .slide-panel-dt-container.is-fullscreen .slide-panel-dt-main {
    min-height: 100%;
    max-height: 100%;
  }
  .slide-panel-dt-container .slide-panel-dt-alternatives-inner,
  .slide-panel-dt-container .slide-panel-dt-sidebar-inner,
  .slide-panel-dt-container .slide-panel-dt-alternatives,
  .slide-panel-dt-container .slide-panel-dt-sidebar {
    min-width: 100% !important;
    width: 100% !important;
    flex-grow: 1;
    overflow: auto;
  }
  .slide-panel-dt-container .slide-panel-dt-alternatives-inner,
  .slide-panel-dt-container .slide-panel-dt-sidebar-inner {
    position: relative !important;
  }
}

.slide-panel-dt-container .slide-panel-dt-main {
  flex-grow: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
}

.slide-panel-dt-container.is-fullscreen .slide-panel-dt-main {
  overflow: visible;
}

.slide-panel-dt-alternatives,
.slide-panel-dt-sidebar-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.slide-panel-dt-container .slide-panel-dt-alternatives,
.slide-panel-dt-container .slide-panel-dt-sidebar {
  flex: 1;
  min-width: 32rem;
  width: 32rem;
  position: relative;
}

.slide-panel-dt-container .slide-panel-dt-alternatives-inner,
.slide-panel-dt-container .slide-panel-dt-sidebar-inner {
  min-width: 32rem;
  width: 32rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.slide-panel-dt-container .slide-panel-dt-sidebar-inner .slide-panel-dt-sidebar-title {
  display: flex;
  font-size: var(--font-size-xsmall);
  margin-bottom: 0.5rem;
  border-bottom: var(--border) var(--color-xlight-grey);
}

.slide-panel-dt-container .slide-panel-dt-sidebar-inner h2 {
  padding: 0.5rem 0;
  margin: 0;
  flex-grow: 2;
  border-bottom: 0;
}

.slide-panel-dt-container .slide-panel-dt-sidebar-inner .slide-panel-dt-sidebar-title .unusual-quality-criteria-count {
  background: var(--color-ocra);
  color: var(--color-white);
  padding: 0.1em 0.4em;
  border-radius: var(--border-radius-small);
  margin-left: 0.8em;
  letter-spacing: 0;
}

.slide-panel-dt-container.is-fullscreen .slide-panel-dt-alternatives,
.slide-panel-dt-container.is-fullscreen .slide-panel-dt-sidebar {
  min-width: 0;
  width: 0;
  overflow: hidden;
  flex: 0;
}

.slide-panel-dt-container .slide-panel-dt-image {
  flex-grow: 2;
  position: relative;
  width: 100%;
  height: 100%;
}

.slide-panel-dt-container .slide-panel-dt-image .slide-panel-dt-image-inner {
  background-color: var(--color-common-black);
  position: relative;
  width: 100%;
  height: 100%;
}

.slide-panel-dt-container.is-fullscreen .slide-panel-dt-image .slide-panel-dt-image-inner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.processing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 4rem;
  height: 4rem;
  border: 0.5rem solid var(--color-blue);
  z-index: 10;
  border-radius: 5rem;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: slide-panel-dt-processing-spin 1s infinite ease-in-out;
}

@keyframes slide-panel-dt-processing-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.slide-panel-dt-container .slide-panel-dt-switchfullscreen {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.slide-panel-dt-container .slide-panel-dt-image .slide-panel-dt-image-inner > img,
.slide-panel-dt-container .slide-panel-dt-image .slide-panel-dt-image-inner > video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: var(--border-thick-size);
  transition: opacity 0.3s ease-in-out;
}

.slide-panel-dt-container.is-fullscreen .slide-panel-dt-image-inner {
  top: -0.5rem;
  left: -0.5rem;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
}

.slide-panel-dt-container .slide-panel-dt-waiting {
  font-size: var(--font-size-small);
  color: var(--color-xxlight-grey);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slide-panel-dt-waiting .slide-container-dt {
  max-width: 10rem;
  opacity: 0.5;
}
