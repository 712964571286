.exam-topbar {
  grid-area: exam-topbar;
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
  border-bottom: var(--border) var(--color-xlight-grey);
}

.exam-topbar_patient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 0;
}
.exam-topbar_episode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 0;
}
.exam-topbar_spacer {
  flex-grow: 2;
}
.exam-topbar_cta {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.exam-topbar label {
  font-size: var(--font-size-xxsmall);
  text-transform: uppercase;
  padding: 0 0.8rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  pointer-events: none;
}

.exam-topbar_episode_exam {
  display: inline-flex;
  gap: 0.5em;
}
.select-input-options .exam-topbar_episode_exam {
  width: 100%;
}
.exam-topbar_episode_exam.placeholder-label .exam-topbar_episode_exam_date {
  color: var(--color-black);
}
.exam-topbar_episode_exam.placeholder-label:not(.placeholder-label_not-editable):hover > span {
  color: var(--color-light-blue);
}
.option.selected .exam-topbar_episode_exam > span {
  color: var(--color-white);
}
.exam-topbar_episode_exam_ga {
  color: var(--color-grey);
}
.exam-topbar_episode_exam_cta {
  margin-left: 1em;
  color: var(--color-blue);
}
.exam-topbar_episode_exam_cta:hover {
  color: var(--color-black);
}
.exam-topbar_episode_exam_cta .icon {
  margin: 0;
}
.exam-topbar_episode_exam_preset {
  flex-grow: 2;
}
.exam-topbar_anonymous-date {
  padding: 0 0.8rem;
}
