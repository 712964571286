.disclaimer-container {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: var(--color-white);
  z-index: 2147483647;
  display: flex;
  align-items: center;
  padding: 4rem 12rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

.disclaimer-container .disclaimer {
  max-height: 80vh;
  overflow: auto;
}

.disclaimer-container .small {
  line-height: 1.2em;
  font-size: 0.75rem;
}

.disclaimer-container .submit {
  margin-top: 2rem;
  text-align: center;
  font-size: 1rem;
}

.disclaimer-container .disclaimer-set-language {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: var(--font-size-small);
}
