.measurements-management .measurements-table {
  margin-top: 0.5rem;
  /* padding: 0.5rem; */
}

.measurements-management .measurements-table .measurements-row > * {
  padding: 0.1rem 0.5rem;
  position: relative;
  vertical-align: bottom;
}
.measurements-management .measurements-table .measurements-row > .value {
  min-width: 6rem;
}

.measurements-management .measurements-table .measurements-row:not(.column-heading) > * {
  overflow: hidden;
}

.measurements-management .measurements-table .measurements-row:not(.inline-title):not(.column-heading) {
  height: 2.3rem;
}

.measurements-management .measurements-table .measurements-row:not(.inline-title) {
  position: relative;
  display: grid;
  grid-template-columns: 1.6fr 0.6fr 0.4fr 0.8fr 0.4fr 0.4fr 0.8fr 0.8fr 0.25fr;
  text-align: center;
  gap: var(--border-size);
  border-radius: 5px;
}

.measurements-management .measurements-table .measurements-row.inline-title {
  margin: 1rem 0;
  border-bottom: none;
  grid-template-columns: 1fr;
}

.measurements-management .measurements-table .measurements-row.not-visible {
  opacity: 0.4;
}

.measurements-management .measurements-table .measurements-row .label {
  display: flex;
  align-items: center;
}

.measurements-management .measurements-table .measurements-row .label .identifier {
  margin-left: 1rem;
}

.measurements-management .measurements-table .measurements-row .border-left {
  border-left: var(--border) var(--color-light-blue-40);
}

.measurements-management .measurements-table .measurements-row .visibility > button {
  cursor: pointer;
}

.measurements-management .measurements-table .measurements-row .disable-click > button {
  cursor: not-allowed;
}

.measurements-management .measurements-table .measurements-row:not(.column-heading):not(.inline-title):hover {
  background-color: var(--color-xlight-grey-40);
}

.measurements-management .measurements-table .measurements-row.column-heading {
  font-size: var(--font-size-small);
  text-align: center;
  padding: 0;
  gap: var(--border-size);
  font-weight: bold;
  background-color: var(--color-light-blue-40);
  margin-bottom: 1rem;
}

.measurements-management .fetus-selection {
  display: flex;
  flex-direction: row;
}

.measurements-management .fetus-selection > * {
  padding: 1rem 1rem 1rem 0;
}

.measurements-management .tabs-wrapper {
  margin-bottom: 1rem;
}

.measurements-management .subtabs-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.measurements-management .subtabs-wrapper > * {
  padding-right: 1rem;
}

.measurements-management .measurements-select-dating {
  font-size: var(--font-size-small);
  padding: 0.4rem;
  margin-top: 0.4rem;
  font-style: italic;
}

.measurements-management .select-input > .select-input-current {
  background: var(--color-xlight-grey);
}

.measurements-management .select-input > .select-input-current > .icon.down {
  background: var(--color-xlight-grey);
}
