.dashboard-container {
  padding: 3rem 3rem 4rem;
  margin: 0 4rem;
  height: 100%;
}

.dashboard-container h1 {
  font-size: var(--font-size-large);
  border-bottom: var(--border) var(--color-grey);
  padding-bottom: 0.4rem;
  margin-bottom: 0.7rem;
}
.theme-dark .dashboard-container h1 {
  border-bottom: var(--border) var(--color-xxlight-grey);
}

/* drafts */
.dashboard-container .dashboard-drafts,
.dashboard-container .dashboard-pending {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.dashboard-container .dashboard-load-more {
  text-align: center;
  font-size: var(--font-size-xsmall);
  margin-top: -2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}
.dashboard-container .dashboard-load-more::before,
.dashboard-load-more::after {
  content: '';
  flex-grow: 2;
  align-items: center;
  border-top: var(--border) var(--color-xxlight-grey);
  margin: 0 1rem 0 0;
}
.dashboard-container .dashboard-load-more::after {
  margin: 0 0 0 1rem;
}

.start-new-exam {
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
  min-height: 15rem;
}
.dashboard-container .dashboard-new-exam-button {
  flex-basis: 50%;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 15rem;
  border-radius: var(--border-radius-small);
  background: var(--color-xxxlight-grey);
  padding: 1rem;
}
.dashboard-container .dashboard-new-exam-button > .label {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.dashboard-container .dashboard-new-exam-button > .icon-wrapper {
  font-size: var(--font-size-xlarge);
  margin-bottom: 0.3rem;
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  padding: 0.3em;
}
.dashboard-container .dashboard-new-exam-info {
  text-align: center;
  color: var(--color-light-grey);
  font-size: var(--font-size-small);
  margin-bottom: 2rem;
}

.dashboard-container .manager-dashboard-new-exam-info {
  text-align: center;
  color: var(--color-light-grey);
  font-size: var(--font-size-small);
}
