.examination-live-container {
  margin-left: var(--section-navbar-width);
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  align-items: stretch;
  grid-template-areas:
    'exam-topbar exam-topbar'
    'examination-live_sidebar examination-live_main';
  overflow: hidden;
  height: 100%;
  max-height: 100svh;
  z-index: 10;
  position: relative;
}

/* remove left margin on slide detached window */
.detached-page-container[data-view='slide'] .examination-live-container {
  margin-left: 0;
}

.examination-live-sidebar {
  grid-area: examination-live_sidebar;
  position: relative;
  width: 0;
  height: 100%;
  max-height: 100vh;
}

.examination-live_child-window-warning {
  position: fixed;
  display: flex;
  padding: 1rem;
  top: 0;
  left: 0;
  width: var(--section-navbar-width);
  z-index: 1000;
  pointer-events: none;
  background: var(--color-common-blue);
  color: var(--color-common-white);
  justify-content: center;
  align-items: center;
}
.examination-live_child-window-warning > span {
  writing-mode: tb;
  rotate: 180deg;
  font-size: var(--font-size-medium);
  font-weight: bold;
}

.examination-live-content {
  grid-area: examination-live_main;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  z-index: 10;
}

.examination-live-top {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.4rem 0.8rem 1.6rem;
  gap: 1rem;
  position: relative;
  z-index: 100;
}

.examination-live-container.status-completed .examination-live-ended-container.detect + .examination-live-top {
  max-width: 50%;
}

.examination-live-trimester {
  font-size: var(--font-size-medium);
  white-space: nowrap;
  line-height: 0;
}

.examination-live-settings {
  font-size: var(--font-size-medium);
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.examination-live-settings > .feedback {
  font-size: var(--font-size-small);
  white-space: nowrap;
}

.examination-live-settings > .feedback .icon {
  cursor: pointer;
  background: var(--color-xxlight-grey);
  color: var(--color-white);
  border-radius: var(--border-radius-large);
}

.examination-live-settings > .feedback .icon:hover {
  background: var(--color-light-blue);
}

.examination-live-searchbar {
  flex-grow: 2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.examination-live-main {
  flex-grow: 2;
  display: flex;
  overflow: hidden;
  position: relative;
  z-index: 20;
  height: 1rem; /* will stretch, since contained in a grid */
}

.examination-live-panel {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--color-xxxlight-grey);
  z-index: 101;
}

.examination-live-panel.open {
  left: 0;
}

.examination-live-panel .panel-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 100;
}

.examination-live-panel .panel-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
  z-index: 150;
  transition: right 0.2s ease-in-out;
}
.examination-live-panel:has(.report-sidebar-wrapper.open) .panel-close {
  right: 16rem;
}

.examination-live-panel .panel-close > .icon {
  font-size: var(--font-size-xlarge);
  text-shadow: 0 0 0.5rem var(--color-common-black);
}

.examination-live-panel .panel-close > .icon:hover {
  color: var(--color-black);
}

.examination-live-panel.selected-plane {
  z-index: 200;
}

.examination-live-panel.selected-plane .panel-close {
  background: var(--color-xxxlight-grey);
  border-left: var(--border) var(--color-xxlight-grey);
}

/* 
.examination-live-pause {
  position: fixed;
  left: 0.8rem;
  z-index: 200;
  top: calc(100% - 3.5rem);
  text-align: left;
  color: var(--color-black);
  cursor: pointer;
  white-space: nowrap;
  transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out,
    width 0.3s ease-in-out;
  width: 2.5rem;
  overflow: hidden;
  border-radius: var(--border-radius-large);
}

.examination-live-container.has-notifications .examination-live-pause {
  top: calc(100% - 7rem);
}
 */

.exam_voice-wrapper {
  position: fixed;
  left: 0.8rem;
  z-index: 200;
  top: calc(100% - 3.5rem);
  color: var(--color-black);
  cursor: pointer;
  white-space: nowrap;
  transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out, width 0.3s ease-in-out;
  border-radius: var(--border-radius-large);
}
.exam_voice-button {
  font-size: var(--font-size-large);
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
  background-color: var(--color-xxlight-blue);
  border: var(--border) var(--color-xxlight-grey-80);
  width: 1.8em;
  height: 1.8em;
  cursor: pointer;
}
.exam_voice-button .recording-animation {
  display: flex;
  gap: 0.1em;
  align-items: center;
  justify-content: center;
}
.exam_voice-button .recording-animation > span {
  width: 0.3em;
  height: calc(1em / 256 * var(--volume));
  min-height: 0.3em;
  max-height: 1em;
  background: var(--color-white);
  border-radius: 1em;
}
.exam_voice-button .recording-animation > :is(span:nth-child(1), span:nth-child(3)) {
  height: calc(0.7em / 256 * var(--volume));
}
.exam_voice-wrapper[data-is-recording='true'] .exam_voice-button {
  background-color: var(--color-blue);
}
.exam_voice-submenu {
  position: absolute;
  bottom: -0.5rem;
  right: 0;
  background: var(--color-white);
  border-radius: 1em;
  border: var(--border) var(--color-xxlight-grey);
  min-width: 1.8em;
  text-align: center;
  padding: 0.4em;
  font-size: var(--font-size-xsmall);
  line-height: var(--font-size-xsmall);
}
.exam_voice-submenu.highlight {
  background: var(--color-common-blue);
}
.exam_voice-submenu:hover {
  background: var(--color-light-grey);
}
.exam_voice-submenu_options {
  padding: 0.5rem;
}
.exam_voice-submenu_options > div {
  cursor: pointer;
}
.exam_voice-submenu_options > div:hover {
  color: var(--color-light-blue);
}

.examination-live-pause.compact {
  width: 2.5rem !important;
}

.examination-live-pause .icon {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.3rem;
  background: var(--color-white);
  border: var(--border) var(--color-xxlight-grey);
  border-radius: var(--border-radius-large);
  font-size: var(--font-size-xlarge);
  vertical-align: middle;
  margin-right: 0.5rem;
  position: relative;
  z-index: 5;
}

.examination-live-pause.paused {
  width: 15rem;
}

.examination-live-pause.paused .icon {
  background: var(--color-ocra);
  color: var(--color-white);
  border-color: var(--color-ocra);
}

.examination-live-pause.paused .icon.paused-with-no-print {
  background: var(--color-xxlight-grey);
  border-color: var(--color-xxlight-grey);
}

.examination-live-pause .icon.printable-icon {
  margin-left: 0.5rem;
  border-color: var(--color-ocra);
  background: var(--color-ocra);
  color: var(--color-white);
}

.examination-live-pause:not(.printable) .icon.printable-icon {
  color: var(--color-white);
  background: var(--color-xlight-grey);
  border-color: var(--color-xxlight-grey);
}

.examination-live-pause label {
  color: var(--color-white);
  background: var(--color-dark-ocra);
  padding: 0.5rem 1rem;
  padding-left: 3rem;
  margin-left: -3rem;
  margin-right: 0.5rem;
  border-radius: var(--border-radius-large);
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
}

.examination-live-end {
  font-size: var(--font-size-xxlarge);
  border-left: var(--border) var(--color-xxlight-grey);
  padding-left: 0.5rem;
}

.examination-live-end .icon {
  cursor: pointer;
}

.examination-live-end .icon:hover {
  color: var(--color-light-blue);
}

.examination-live-stats-panel-container {
  position: relative;
  height: 100%;
}

.examination-sharing-flow-top-bar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
}
.exam-sharing-flow_status {
  font-size: var(--font-size-xsmall);
}
.exam-sharing-flow_status > .icon {
  color: var(--color-ocra);
}
