.dx-sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.dx-sidebar-container .dx-sidebar-tab {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0.5rem;
  overflow: auto;
  left: -1rem;
  position: relative;
  width: calc(100% + 2rem);
  padding: 0 1rem;
}
.dx-sidebar-container .dx-sidebar-fetus-controller {
  justify-content: center;
}

.dx-sidebar-container .examination-sidebar-switch {
  padding: 0 0 1rem 3rem;
  text-align: right;
  font-size: var(--font-size-large);
}

.dx-sidebar-container .foetus-controller > div {
  width: calc(100% + 2.5rem);
  max-width: none;
  position: relative;
  left: -1.5rem;
  overflow: hidden;
}

.dx-sidebar-container .dx-sidebar-tab h3 {
  font-size: var(--font-size-xsmall);
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  border-bottom: var(--border) var(--color-light-grey);
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.dx-sidebar-tab-malformations {
  margin-bottom: 1rem;
}
.dx-sidebar-tab-malformations .dx-sidebar-add-malformation,
.dx-sidebar-tab-medicalhistory .dx-sidebar-add-risk-factor {
  text-align: center;
  margin-top: 0.5rem;
}
.dx-sidebar-tab-medicalhistory .dx-sidebar-add-risk-factor {
  margin-bottom: 1rem;
}
.dx-sidebar-tab-malformations ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: var(--font-size-small);
}
.dx-sidebar-tab-malformations ul li {
  display: flex;
  border-bottom: var(--border) var(--color-xxlight-grey);
  padding: 0.8em 0.4em 0.8em 0.6em;
  margin: 0;
  cursor: pointer;
  line-height: var(--font-size-small);
  background: var(--color-white);
}
.dx-sidebar-tab-malformations .dx-sidebar-tab-marlformations-no > div {
  font-size: var(--font-size-xsmall);
  text-align: center;
}
.dx-sidebar-tab-malformations .dx-sidebar-tab-marlformations-no > ul {
  height: 0;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}
.dx-sidebar-tab-malformations ul li:last-child {
  border-bottom: 0;
}
.dx-sidebar-tab-malformations ul li.status-yes {
  color: var(--color-ocra);
}
.dx-sidebar-tab-malformations ul li.status-no {
  color: var(--color-blue);
}
.dx-sidebar-tab-malformations ul li .malformation-name:hover {
  color: var(--color-light-blue);
}
.dx-sidebar-tab-malformations ul li.status-yes .malformation-name:hover {
  color: var(--color-light-ocra);
}
.dx-sidebar-tab-malformations ul li .malformation-name {
  flex-grow: 2;
}
.dx-sidebar-tab-malformations ul li .malformation-cta {
  color: var(--color-blue);
}
.dx-sidebar-tab-malformations ul li .malformation-cta:hover {
  color: var(--color-black);
}

.dx-checklist-summary-btn button {
  width: 100%;
  position: relative;
}
.dx-checklist-summary-btn .go-to-diagnostic-panel {
  font-size: var(--font-size-small);
  margin-bottom: 0.2rem;
}

.dx-checklist-summary-btn .go-to-summary {
  position: relative;
}
.dx-checklist-summary-btn .go-to-summary .dx-sidebar-reliability-meter {
  position: absolute;
  bottom: 0.6rem;
  right: 1rem;
  min-width: 1.6rem;
  max-width: 1.6rem;
  z-index: 10;
}
.dx-checklist-summary-btn .go-to-summary .dx-sidebar-reliability-meter .progress-ring-container label {
  color: var(--color-blue);
  font-size: var(--font-size-medium);
  line-height: 0;
}
.dx-checklist-summary-btn .go-to-summary.open .dx-sidebar-reliability-meter .progress-ring-container label {
  color: var(--color-xxlight-grey);
}
.dx-checklist-summary-btn
  .go-to-summary.open
  .dx-sidebar-reliability-meter
  .progress-ring-container
  svg.circular-meter
  #progress {
  stroke: var(--color-xxlight-grey);
}

.dx-sidebar-progress-meter {
  position: relative;
}
.dx-sidebar-progress-meter > .progress-bar {
  height: 0.2rem;
  margin-bottom: 0.3rem;
  display: flex;
  gap: 0.2rem;
}
.dx-sidebar-progress-meter > .progress-bar .section {
  position: relative;
  height: 100%;
  background: var(--color-xxlight-grey);
  flex-grow: 2;
  border-radius: var(--border-radius);
}
.dx-sidebar-progress-meter > .progress-bar .section.unusual:after {
  display: none;
  content: '';
  position: absolute;
  width: 0.2rem;
  height: 0.2rem;
  top: 0;
  left: 0;
  background: var(--color-ocra);
  border-radius: var(--border-radius);
}
.dx-sidebar-progress-meter > .progress-bar .section.current {
  background: var(--color-black) !important;
}
.dx-sidebar-progress-meter > .progress-bar .section.validated {
  background: var(--color-common-blue);
}
