.percentile-graph-wrapper svg .background {
  fill: transparent;
  stroke: var(--color-light-grey);
  stroke-width: 1;
}
.percentile-graph-wrapper svg line.half {
  stroke: var(--color-light-grey);
  stroke-width: 0.5;
}
.percentile-graph-wrapper svg .marker {
  fill: var(--color-blue);
}

.percentile-graph-wrapper svg.at-risk .background {
  stroke: var(--color-light-ocra);
}
.percentile-graph-wrapper svg.at-risk line.half {
  stroke: var(--color-light-ocra);
}
.percentile-graph-wrapper svg.at-risk .marker {
  fill: var(--color-ocra);
}
