.smartmatch-dt-alternatives {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.smartmatch-dt-slide-container {
  position: relative;
  flex-grow: 2;
  min-width: 40%;
  background: var(--color-xxlight-grey-40);
  border-radius: var(--border-radius-small);
  cursor: pointer;
  position: relative;
  padding: 0;
}

.smartmatch-dt-slide-name {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: var(--color-black);
  font-size: var(--font-size-medium);
  line-height: var(--font-size-medium);
  z-index: 10;
  text-shadow: 0 0.1em 0 var(--color-common-black), 0.1em 0 0 var(--color-common-black),
    0 -0.1em 0 var(--color-common-black), -0.1em 0 0 var(--color-common-black);
}
.smartmatch-dt-slide-category {
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
  display: block;
  margin-top: 0.2rem;
}
.smartmatch-dt-alternatives-more-slides {
  text-align: center;
  background: var(--color-xxlight-grey-40);
  border-radius: var(--border-radius-small);
  padding: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}
