.medical-history-input {
  position: relative;
}
.medical-history-input-dismiss {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;
}
.medical-history-input-dismiss:hover {
  color: var(--color-black);
}

.fetus-sex-popup .disclaimer {
  margin-top: 2em;
  font-size: var(--font-size-xsmall);
  color: var(--color-light-grey);
  max-width: 20rem;
  line-height: var(--font-size-small);
}
