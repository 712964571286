.confirm-dialog-title {
  font-size: var(--font-size-medium);
  font-weight: 700;
  line-height: var(--font-size-medium);
  color: white;
  margin-bottom: 2rem;
}

.confirm-dialog-content {
  font-size: var(--font-size-medium);
  font-weight: 400;
  line-height: var(--font-size-large);
  margin: 0 auto 2rem;
  color: white;
}

.confirm-dialog-checkbox {
  font-size: var(--font-size-xsmal);
  font-weight: 400;
  line-height: var(--font-size-medium);
  margin: 0 auto 2rem;
  color: white;
}

.confirm-dialog-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
